import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { Redirect } from "react-router-dom";

import { useTextInput, useNumberInput } from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";

import { Confirmation } from "../confirmation";
import { CSSTransition } from "react-transition-group";
import { Button } from "components/buttons";
import { Segment, Dropdown } from "semantic-ui-react";
import { Loader } from "components/loader";
import { arrayToObject, formatPrice } from "services/utils";
import { WithdrawPreview } from "components/withdraw-preview";
import {
  editCommerceBankAccount,
  getCommerceAmounts,
  withdrawCommerceFunds,
  withdrawCommerceFundsPreview,
} from "services/api";

//import bancamigaCard from "assets/img/fondo_bancamiga.png";
//import bancamigaLogo from "assets/img/isotipo_nombre.png";

const BankAccountDetailModel = ({
  className,
  accountSelected,
  viewType,
  handleBack,
  bankList,
  edit,
  update,
  throwPopupMessage,
  closePopup,
}) => {
  if (!accountSelected) {
    return (
      <p
        style={{
          marginTop: "10rem",
          paddingTop: "5rem",
          textAlign: "center",
        }}
      >
        No ha seleccionado una ninguna cuenta
      </p>
    );
  }
  const {
    setValue: setBankName,
    key: bankCode,
    bind: { value: bankName, onChange: handleBankName },
  } = useDropdown(accountSelected.bank_name ? accountSelected.bank_name : "");
  const {
    setValue: setAccountNumber,
    bind: { value: accountNumber, onChange: handleAccountNumber },
  } = useNumberInput(
    accountSelected.account_number ? accountSelected.account_number : ""
  );
  const {
    setValue: setAccountName,
    bind: { value: accountName, onChange: handleAccountName },
  } = useTextInput(
    accountSelected.account_name ? accountSelected.account_name : ""
  );
  const {
    setValue: setAccountDescription,
    bind: { value: accountDescription, onChange: handleAccountDescription },
  } = useDropdown(
    accountSelected.account_description
      ? accountSelected.account_description
      : ""
  );
  const {
    setValue: setDocId,
    bind: { value: docId, onChange: handleDocId },
  } = useNumberInput(accountSelected.doc_id ? accountSelected.doc_id : "");
  const {
    setValue: setDocType,
    bind: { value: docType, onChange: handleDocType },
  } = useDropdown(accountSelected.doc_type ? accountSelected.doc_type : "V");

  const [authError, setAuthError] = useState(false);
  const [amountLoader, setAmountLoader] = useState(true);
  const [amountToShow, setAmountToShow] = useState("");
  const [amount, setAmount] = useState(0);
  const [sendWithdraw, setSendWithdraw] = useState(false);
  const [submitChanges, setSubmitChanges] = useState(false);
  const [preview, setPreview] = useState({
    data: null,
    loading: false,
    getData: false,
    show: false,
  });

  useEffect(() => {
    amountsFunction();
  }, []);

  useEffect(() => {
    const withdraw = async () => {
      try {
        throwPopupMessage("alert", <Loader margin="2rem 0 0 0" />);
        const response = await withdrawCommerceFunds({
          bank_account_id: accountSelected._id,
          amount,
          currency: "BS",
        });
        const res = await response.json();
        if (res.error) throw res.error;
        throwPopupMessage("success", "Retiro de fondos exitoso");
        update();
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          //this.props.clearReduxStore();
          //return;
          setAuthError(true);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    const getPreview = async () => {
      try {
        setPreview((prev) => ({ ...prev, loading: true, show: true }));

        const response = await withdrawCommerceFundsPreview({
          currency: "BS",
        });
        const res = await response.json();
        if (res.error) throw res.error;
        setPreview((prev) => ({
          ...prev,
          loading: false,
          data: {
            ...res,
          },
          show: true,
        }));
      } catch (error) {
        setPreview((prev) => ({ ...prev, loading: false }));
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          setAuthError(true);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (sendWithdraw) {
      withdraw();
      setSendWithdraw(false);
    }
    if (preview.getData) {
      getPreview();
      setPreview((prev) => ({
        ...prev,
        getData: false,
      }));
    }
  }, [sendWithdraw, preview.getData]);

  useEffect(() => {
    const submitFunction = async () => {
      try {
        throwPopupMessage("alert", <Loader margin={"2rem 0 0 0"} />);
        const response = await editCommerceBankAccount(accountSelected._id, {
          bank_name: bankName,
          bank_code: bankCode !== "" ? bankCode : accountNumber.slice(0, 4),
          account_name: accountName, // A NOMBRE DE
          account_type: "Nacional", // Nacional o Internacional
          account_number: accountNumber,
          account_description: accountDescription, //Tipo de cuenta
          currency: "BS",
          doc_id: docId,
          doc_type: docType,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        throwPopupMessage("success", "Cambios realizados con éxito");
        setBankName("");
        setAccountName("");
        setAccountNumber("");
        setAccountDescription("");
        setDocId("");
        setDocType("V");
        update();
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          //this.props.clearReduxStore();
          //return;
          setAuthError(true);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (submitChanges) {
      setSubmitChanges(false);
      submitFunction();
    }
  }, [submitChanges]);

  const handleEditConfirmation = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "alert",
      <Confirmation
        handleClose={closePopup}
        handleSubmit={handleSubmit}
        text={"¿Desea conservar los cambios?"}
      />
    );
  };

  const handleWithdrawConfirmation = (event) => {
    event.preventDefault();
    setSendWithdraw(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitChanges(true);
  };

  const handleWithdraw = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "alert",
      <Confirmation
        handleClose={closePopup}
        handleSubmit={handleWithdrawConfirmation}
        text={
          <div>
            <p>¿Desea retirar los fondos disponibles?</p>
            <div
              style={{
                marginTop: "0.5rem",
                borderRadius: "1rem",
                border: "3px solid #e0f5ff",
                backgroundColor: "#e4f5ff",
              }}
            >
              <p>{bankName}</p>
              <p>{accountNumber}</p>
              <p>{accountName}</p>
              <p>
                {docType}-{docId}
              </p>
            </div>
          </div>
        }
      />
    );
  };

  const handleWithdrawPreview = (event) => {
    event.preventDefault();
    setPreview((prev) => ({ ...prev, getData: true }));
  };

  const amountsFunction = async () => {
    try {
      setAmountLoader(true);
      const response = await getCommerceAmounts();
      const res = await response.json();
      if (res.error) throw res.error;
      const hasAmounts = res.user_amounts && res.user_amounts.length;
      const amounts = hasAmounts
        ? arrayToObject(res.user_amounts, "currency")
        : { BS: { funds: 0 } };
      const hasFunds = amounts.BS.funds > 0;
      const amountRes = hasFunds ? amounts.BS.funds : 0;
      const fundsToShow = hasFunds ? formatPrice(amounts.BS.funds) : "0";
      setAmount(amountRes);
      setAmountToShow(fundsToShow);
      setAmountLoader(false);
    } catch (e) {
      throwPopupMessage("error", e.description);
    }
  };
  const handlePreviewBack = (event) => {
    event.preventDefault();
    setPreview((prev) => ({
      ...prev,
      data: null,
      loading: false,
      show: false,
    }));
  };
  if (authError) return <Redirect to="/" />;
  if (preview.show)
    return (
      <WithdrawPreview
        data={preview.data}
        show={preview.show}
        loading={preview.loading}
        onWithdraw={handleWithdraw}
        back
        onBack={handlePreviewBack}
      />
    );
  return (
    <div className={className}>
      <CSSTransition
        in={true}
        timeout={350}
        appear={true}
        classNames="register"
      >
        <div className="register">
          <p className="title">Detalle</p>

          <Segment.Group className="register-form" autoComplete="off">
            <div className="ui transparent input segment">
              <Dropdown
                className="label"
                fluid
                scrolling
                selectOnBlur={false}
                placeholder="Banco"
                name="Banco"
                value={
                  edit
                    ? bankName
                    : accountSelected.bank_name
                    ? accountSelected.bank_name
                    : ""
                }
                options={bankList}
                onChange={handleBankName}
                disabled={!edit}
              />
            </div>
            <div className="ui transparent input segment">
              <input
                style={{ textAlign: "justify" }}
                name="account_number"
                type="text"
                minLength={20}
                maxLength={20}
                autoComplete="new_bank_number"
                placeholder="Número de cuenta"
                onChange={handleAccountNumber}
                value={
                  edit
                    ? accountNumber
                    : accountSelected.account_number
                    ? accountSelected.account_number
                    : ""
                }
                disabled={!edit}
              />
            </div>
            <div className="ui transparent input segment">
              <input
                name="account_name"
                type="text"
                style={{ textAlign: "justify" }}
                autoComplete="new-account_name"
                placeholder="A nombre de"
                onChange={handleAccountName}
                value={
                  edit
                    ? accountName
                    : accountSelected.account_name
                    ? accountSelected.account_name
                    : ""
                }
                disabled={!edit}
              />
            </div>
            <div className="ui transparent input segment">
              <Dropdown
                className="label "
                fluid
                scrolling
                selectOnBlur={false}
                name="account_description"
                placeholder="Tipo de Cuenta"
                value={
                  edit
                    ? accountDescription
                    : accountSelected.account_description
                    ? accountSelected.account_description
                    : ""
                }
                options={[
                  {
                    key: 0,
                    text: "Cuenta Corriente",
                    value: "Cuenta Corriente",
                  },
                  {
                    key: 1,
                    text: "Cuenta de Ahorros",
                    value: "Cuenta de Ahorros",
                  },
                ]}
                onChange={handleAccountDescription}
                disabled={!edit}
              />
            </div>
            <div className="ui transparent left labeled input segment">
              <Dropdown
                className="label"
                name="doc_type"
                value={
                  edit
                    ? docType
                    : accountSelected.doc_type
                    ? accountSelected.doc_type
                    : "V"
                }
                options={[
                  { key: 0, text: "V.", value: "V" },
                  { key: 1, text: "J.", value: "J" },
                  { key: 2, text: "E.", value: "E" },
                ]}
                onChange={handleDocType}
                disabled={!edit}
              />
              <input
                style={{ textAlign: "justify" }}
                name="doc_id"
                type="text"
                maxLength={9}
                autoComplete="off"
                placeholder="Número"
                value={
                  edit
                    ? docId
                    : accountSelected.doc_id
                    ? accountSelected.doc_id
                    : ""
                }
                onChange={handleDocId}
                disabled={!edit}
              />
            </div>
          </Segment.Group>
          <div className="selectors" key="selectors">
            {edit && (
              <Button
                color="blue"
                disabled={
                  bankName === "" ||
                  accountNumber === "" ||
                  accountNumber.length < 20 ||
                  accountName === "" ||
                  accountDescription === "" ||
                  docId === "" ||
                  docType === ""
                }
                key="buttom-submit"
                onClick={handleEditConfirmation}
                value="edit"
              >
                {" "}
                Confirmar Cambios
              </Button>
            )}
            {!edit && !amountLoader && (
              <div className="wallet">
                <p> Fondos disponibles: {amountToShow} Bs.S </p>

                {/* IMPORTANTE COLOCAR OTRA VEZ 
                <img src={bancamigaCard} alt="card" className="card" />
                <img src={bancamigaLogo} alt="logo" className="logo" />
                <p className="currency">BS</p>
                <p className="legend">Disponible</p>
                <p className="amount">{`BS ${amountToShow}`}</p> */}
              </div>
            )}
            {!edit && amountLoader && <Loader margin="3rem 0 0 0" />}
            {!edit && !amountLoader && (
              <Button
                color="green"
                disabled={edit /* || amount === 0 */}
                key="buttom-submit"
                onClick={handleWithdrawPreview}
                value="withdraw"
                margin={"1rem 0 0 0"}
              >
                {" "}
                Retirar Fondos
              </Button>
            )}
            {viewType === "phone" && !amountLoader && (
              <div className="back">
                <Button
                  color="grey"
                  style={{ marginBottom: "1.5rem" }}
                  onClick={handleBack}
                >
                  Volver
                </Button>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage,
  closePopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountDetailModel);
