import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "services/utils";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { getTransactionsTags } from "redux/selectors/constants";
import {
    usersAutocomplete,
    getSuperAdminTransactions,
    discountAmount,
    convertToPremium,
    quitPremium,
    premiumInfo,
    ticketsAllocate,
    usersUpdate,
    resetUserPassword,
    bancamigaOrderStatus,
} from "services/api";
import { reportsAPI, superadminAPI } from "api";
import moment from "moment";
import { debouncer_timeout } from "assets/strings/constants";
import MposUsersAdminView from "./components/users-admin-view";
import { throwPopupMessage, closePopup } from "components/popup-message";
import MposUsersAdminPhoneView from "./components/users-admin-phone-view";
import { UserRefList } from "./components/users-ref";
import {
    getSelectedCommerce as getSelectedCommerceFromRedux,
    getPermissions as getPermissionsFromRedux,
    getBuildings as getBuildingsFromRedux,
    getSession as getSessionFromRedux,
} from "redux/selectors";
import {
    handleSearchTypeButtons,
    handleSearchFilterButtons,
    handleSearchCurrencyButtons,
    handleFromDate,
    handleEndDate,
} from "components/movements-form";
//import {joinSocket, addSocketListener, removeSocketListener, disconnectSocket} from "services/websockets";
import { inputPrice, priceToRequest, formatPrice } from "services/utils";
import {
    userVehicleData,
    userVehicleDelete,
    userVehicleUpdate,
    //usersVehicleInfo,
} from "../../services/api";
class MPosUsers extends PureComponent {
    constructor(props) {
        super(props);
        let commerce_id = props.getSelectedCommerce._id;
        let operationalEmail = props.getSession.email;
        this.state = {
            transactionTags: props.transactionTags,
            vehicleData: [],
            vehicleId: "",
            usersVehicles: [],
            newLicensePlate: "",
            newBrand: "",
            newModel: "",
            newColor: "",
            newYear: "",
            newAlias: "",
            newType: "light-car",
            newVerified: false,
            newIsActive: false,

            isVehicleSelected: false,

            viewState: "user_search",
            searchParam: "",
            currentUsersPage: 1,
            commerce_id,
            users: [],
            nextUsersPageExists: false,
            showResponsePanel: false,
            selectedUser: null,
            contactPhone: "",
            comments: [],
            operationalEmail,

            // movements by user
            searchType: "latests", // enum ['latests','dateRange']
            searchFilter: "",
            promotionTickets: 0,
            selectionUserOptions: false,
            optionSelected: "",
            amountInput: "",
            amountToDiscount: 0,
            loadingPremium: false,
            searchCurrency: "BS",
            fromDate: null,
            endDate: null,
            movements: [],
            nextPageExist: false,
            currentPage: 1,
            selectedMovement: null,
            showMovementsSearch: true,

            // ModifyData control strings
            newName: "",
            newEmail: "",
            newPhone: "",
            newMobilePhone: "",
            newId: "",
            newIdType: "",
            newLastName: "",
            newTimeout: "",
            newConnectionTimeout: "",

            ticketType: "promotion_tickets",
            selectionMovementOptions: false,
            serviceFilter: true,

            // 'touched' control booleans
            nameTouched: false,
            lastNameTouched: false,
            emailTouched: false,
            idTouched: false,
            idTypeTouched: false,
            phoneTouched: false,
            mobilePhoneTouched: false,
            timeoutTouched: false,
            newConnectionTimeoutTouched: false,
            serviceFilterTouched: false,
            fromDateReport: null,
            endDateReport: null,

            // viewType: 'phone' | is set when component mounts
            popupMessages: null,
            popupMessagesType: "",
            showPopup: false,
            redirect: null,
        };
        // ========== INSTANCE PROPERTIES ==========
        this.initialState = { ...this.state };
        this.username = null;
        this.remoteAccess = false;
        this.remoteStateToBack = null;
        this.formDataCache = {};
        this.movementsPerPage = 10;

        // Bind popup message functions
        this.handleSearchTypeButtons = handleSearchTypeButtons.bind(this);
        this.handleSearchFilterButtons = handleSearchFilterButtons.bind(this);
        this.handleSearchCurrencyButtons =
            handleSearchCurrencyButtons.bind(this);
        this.handleFromDate = handleFromDate.bind(this);
        this.handleEndDate = handleEndDate.bind(this);

        this.throwPopupMessage = throwPopupMessage.bind(this);
        this.closePopup = closePopup.bind(this);
    }

    DEFAULT_RESULTS_PER_PAGE = 10;
    // =============== STATE DOCS ===============
    /*
  
  --> user_search
    { ...this.initialState }
    Error messages will be shown here
  
  --> user_search_loading
    { ...this.initialState }
    or
    {
      ...this.this.initialState,
      searchParam: String
    }
  
  --> user_search_done
    {
      searchParam: String,
      users: [{one or more}]
    }
  
  --> user_selection
    {
      searchParam: String,
      users: [{one or more}],
      selectedUser: {object},
      showResponsePanel: true
    }
  
  --> user_selection_done
    {
      searchParam: String,
      users: [{one or more}],
      selectedUser: {object},
      showResponsePanel: true
    }
  */
    // =============== END STATE DOCS ===============

    /*
     ** mpos-ticket-digitize on Mount:
     ** --> set viewType
     ** --> set the 'resize' event listener
     */
    //AQUI SUPUESTAMENTE VA UN ASYNC PARA EL COMPONENT_DID_MOUNT
    componentDidMount() {
        // --> Set viewType
        this.setViewType();
        // --> Set 'resize' event listener
        window.addEventListener("resize", this.setViewType);
        document.addEventListener("keydown", this.onEnter);
        if (this.props.location.state && this.props.location.state.user) {
            this.username = this.props.location.state.user;
            let remoteState = this.props.location.state;
            if (remoteState.remoteAccess && remoteState.remoteStateToBack) {
                this.remoteAccess = remoteState.remoteAccess;
                this.remoteStateToBack = remoteState.remoteStateToBack;
            }
            this.props.history.replace({
                pathname: this.props.location.pathname,
                state: {},
            });
            this.getUser(this.username);
        }
    }

    // Remove event listeners
    componentWillUnmount() {
        /*   removeSocketListener();
    disconnectSocket();*/
        window.removeEventListener("resize", this.setViewType);
        document.removeEventListener("keydown", this.onEnter);
    }

    getUser(user) {
        usersAutocomplete(user)
            .then((res) => {
                if (!res.ok) {
                    throw res;
                }
                return res.json();
            })
            .then((res) => {
                if (res.users) {
                    if (!res.users.length) {
                        // if the response has no users, reset view
                        this.setState((prevState) => ({
                            ...this.initialState,
                            searchParam: prevState.searchParam,
                            viewState: "user_search",
                        }));
                    } else {
                        // else, print the tickets
                        this.setState((prevState) => ({
                            ...this.initialState,
                            searchParam: res.users[0].username,
                            users: res.users,
                            selectedUser: res.users[0],
                            showResponsePanel: true,
                        }));
                        if (
                            res.users[0].parking_configuration &&
                            res.users[0].parking_configuration.is_active &&
                            res.users[0].parking_configuration.expire_date &&
                            moment().isBefore(
                                res.users[0].parking_configuration.expire_date
                            )
                        ) {
                            this.setState({
                                viewState: "user_selection_loading",
                            });
                            this.getPremiumInfo(res.users[0]);
                        } else {
                            this.setState({ viewState: "user_selection_done" });
                        }
                    }
                }
            })
            // if an error occurs, reset the view and print the error
            .catch((err) => {
                // If error has a body, check the response
                if (typeof err.json === "function") {
                    err.json().then((err_body) => {
                        // If it is an authentication error, clear the redux-store to close the session
                        if (
                            err_body.error.id === "NO_TOKEN_PROVIDED" ||
                            err_body.error.id === "AUTHENTICATE_FAILED" ||
                            err_body.error.id === "DUPLICATE_SESSION"
                        ) {
                            this.throwPopupMessage(
                                "alert",
                                errors.AUTHENTICATION
                            );
                            //this.props.clearReduxStore();
                            return; // prevent setState exeution on unmounted component
                        }
                        this.setState({ ...this.initialState });
                        this.throwPopupMessage(
                            "error",
                            err_body.error.description
                        );
                    });
                } else {
                    this.setState({ ...this.initialState });
                    this.throwPopupMessage("error", errors.GENERAL_ERR);
                }
            });
    }

    // ========== METHODS ==========
    // 1)
    handleSearchInputChange = (event) => {
        let value = event.target.value;
        // The field is restrcted to accept only letters, numbers, @(first position) and _-.
        if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
            value = value.toLowerCase();

            this.setState({
                ...this.initialState,
                //viewState: "user_search_loading",
                searchParam: value,
            });
            // call the API in a debounced way
            //this.debouncedApiCall(value);
        }
    };

    // 2)
    debouncedApiCall = debounce((value) => {
        // remove the @ from the first position before using it in the request
        value = value[0] === "@" ? value.substr(1) : value;
        // call the API
        let usersPromise = usersAutocomplete(value);
        // Manage the response
        this.handleUsersSearchPromise(usersPromise);
        // Timer
    }, debouncer_timeout);

    // 3)
    handleUsersSearchPromise = (promise) => {
        promise
            .then((res) => {
                if (!res.ok) {
                    throw res;
                }
                return res.json();
            })
            .then((res) => {
                if (res.users) {
                    if (!res.users.length) {
                        // if the response has no users, reset view
                        this.setState((prevState) => ({
                            ...this.initialState,
                            searchParam: prevState.searchParam,
                            viewState: "user_search",
                        }));
                    } else {
                        // else, print the tickets
                        this.setState((prevState) => ({
                            ...this.initialState,
                            searchParam: prevState.searchParam,
                            viewState: "user_search_done",
                            users: res.users,
                            nextUsersPageExists: res.nextPageExists,
                            currentUsersPage: prevState.currentUsersPage,
                        }));
                    }
                }
            })
            // if an error occurs, reset the view and print the error
            .catch((err) => {
                // If error has a body, check the response
                if (typeof err.json === "function") {
                    err.json().then((err_body) => {
                        // If it is an authentication error, clear the redux-store to close the session
                        if (
                            err_body.error.id === "NO_TOKEN_PROVIDED" ||
                            err_body.error.id === "AUTHENTICATE_FAILED" ||
                            err_body.error.id === "DUPLICATE_SESSION"
                        ) {
                            this.throwPopupMessage(
                                "alert",
                                errors.AUTHENTICATION
                            );
                            //this.props.clearReduxStore();
                            return; // prevent setState exeution on unmounted component
                        }
                        this.setState({ ...this.initialState });
                        this.throwPopupMessage(
                            "error",
                            err_body.error.description
                        );
                    });
                } else {
                    this.setState({ ...this.initialState });
                    this.throwPopupMessage("error", errors.GENERAL_ERR);
                }
            });
    };

    getPremiumInfo = async (selectedUser) => {
        try {
            const response = await premiumInfo(selectedUser._id);
            const res = await response.json();
            if (res.error) {
                throw res;
            }
            this.setState({
                viewState: "user_selection_done",
                showResponsePanel: true,
                selectedUser: {
                    ...selectedUser,
                    remaining_premium_days: res.remaining_premium_days,
                    remaining_tickets_per_day: res.remaining_tickets_per_day,
                },
            });
        } catch (err) {
            this.throwPopupMessage(
                "error",
                "Ha ocurrido un error obteniendo la información Premium del usuario"
            );
            this.setState({
                viewState: "user_selection_done",
                showResponsePanel: true,
                selectedUser,
            });
        }
    };
    // 4)
    handleUserSelection = (event) => {
        let index = event.currentTarget.id;
        let selectedUser = this.state.users[index];
        this.setState({
            viewState: "user_selection_loading",
            selectedUser,
        });
        if (
            selectedUser.parking_configuration &&
            selectedUser.parking_configuration.is_active &&
            selectedUser.parking_configuration.expire_date &&
            moment().isBefore(selectedUser.parking_configuration.expire_date)
        ) {
            this.getPremiumInfo(selectedUser);
        } else {
            this.setState({
                viewState: "user_selection_done",
                showResponsePanel: true,
            });
        }
    };
    // 4)
    removeMovementsView = (event) => {
        this.setState({
            viewState: "user_selection_done",
            showResponsePanel: true,
            selectedUser: this.state.selectedUser,
        });
    };

    // 4)
    formatRequestData = (dataObject) => {
        let fromDate, endDate, limit, type, currency;
        let transaction_tag = "";
        currency = this.state.searchCurrency;
        if (this.state.searchType === "dateRange" && dataObject.fromDate)
            fromDate = dataObject.fromDate.toISOString();
        if (this.state.searchType === "dateRange" && dataObject.endDate)
            endDate = dataObject.endDate.toISOString();
        if (this.state.searchFilter === "incomes") type = "credit";
        if (this.state.searchFilter === "expenditures") type = "debit";
        if (this.state.searchFilter === "withdrawals") {
            type = "debit";
            transaction_tag = "withdraw";
        }
        limit = this.movementsPerPage + 1;

        return { fromDate, endDate, limit, type, currency, transaction_tag };
    };

    // 5)
    handleSubmit = (event) => {
        event.preventDefault();
        // format request data
        let { fromDate, endDate, limit, type, currency, transaction_tag } =
            this.formatRequestData(this.state);
        let skip = 0;
        let { users, selectedUser, searchParam } = this.state;
        let user_id = this.state.selectedUser._id;
        let commerce_id = this.state.commerce_id;
        // save a copy of the form state
        this.formDataCache = {
            searchType: this.state.searchType,
            searchFilter: this.state.searchFilter,
            searchCurrency: this.state.searchCurrency,
            fromDate: this.state.fromDate,
            endDate: this.state.endDate,
        };
        // If there are missing fields
        if (
            (this.state.searchType === "dateRange" &&
                (!fromDate || !endDate)) ||
            this.state.searchType === ""
        ) {
            this.setState({
                viewState: "movements_search_done",
            });
            this.throwPopupMessage("error", "Faltan campos obligatorios.");
        } else {
            // Set loading state and starting paination state
            this.setState({
                ...this.initialState,
                selectedUser,
                searchParam,
                users,
                showResponsePanel: true,
                ...this.formDataCache,
                viewState: "movements_search_loading",
            });
            // make the api call
            let promise;
            promise = getSuperAdminTransactions({
                commerce_id,
                fromDate,
                endDate,
                skip,
                limit,
                user_id,
                type,
                currency,
                transaction_tag,
            });
            this.handleMovementsSearchPromise(promise);
        }
    };

    // 6)
    handlePageDown = (event) => {
        event.preventDefault();
        // format request data
        let reqData = this.formatRequestData(this.formDataCache);
        let skip = (this.state.currentPage - 2) * this.movementsPerPage;
        let commerce_id = this.state.commerce_id;
        let user_id = this.state.selectedUser._id;
        let selectedUser = this.state.selectedUser;
        // Set loading state
        this.setState((prevState) => ({
            selectedUser,
            ...this.formDataCache,
            viewState: "movements_pagination_loading",
            currentPage: prevState.currentPage - 1,
            nextPageExist: true,
        }));
        // make the api call
        let promise = getSuperAdminTransactions({
            ...reqData,
            commerce_id,
            skip,
            user_id,
        });
        this.handleMovementsSearchPromise(promise);
    };

    // 7)
    handlePageUp = (event) => {
        event.preventDefault();
        // format request data
        let reqData = this.formatRequestData(this.formDataCache);
        let skip = this.state.currentPage * this.movementsPerPage;
        let user_id = this.state.selectedUser._id;
        let selectedUser = this.state.selectedUser;
        let commerce_id = this.state.commerce_id;
        // Set loading state
        this.setState((prevState) => ({
            selectedUser,
            ...this.formDataCache,
            viewState: "movements_pagination_loading",
            currentPage: prevState.currentPage + 1,
            nextPageExist: true,
        }));
        // make the api call
        let promise = getSuperAdminTransactions({
            ...reqData,
            commerce_id,
            skip,
            user_id,
        });
        this.handleMovementsSearchPromise(promise);
    };

    handlePromotionTickets = (event) => {
        event.preventDefault();
        let inputQty = event.target.value.replace(/[^0-9\s]/g, "");
        let formattingQty = inputQty.replace(" ", "");
        this.setState({
            promotionTickets: formattingQty ? parseInt(formattingQty) : 0,
        });
    };

    handleDeletePromotionTickets = (event) => {
        event.preventDefault();
        let inputQty = event.target.value.replace(/[^0-9\s]/g, "");
        let formattingQty = inputQty.replace(" ", "");
        this.setState({
            promotionTickets: formattingQty ? -parseInt(formattingQty) : 0,
        });
    };
    handleOptionsSelection = async (event, data) => {
        event.preventDefault();
        let optionSelected = data.value;
        let user = this.state.selectedUser.username;
        let userData = this.state.selectedUser;
        this.setState({ selectionUserOptions: true, optionSelected });

        switch (optionSelected) {
            case "return":
                this.throwPopupMessage(
                    "alert",
                    `Indique el monto que desea descontar al usuario`
                );
                break;
            case "Activar premium":
                this.throwPopupMessage(
                    "alert",
                    `¿Deseas activar el paquete premium al usuario @${user}?`
                );
                break;
            case "Desactivar premium":
                this.throwPopupMessage(
                    "alert",
                    `¿Deseas desactivar el paquete premium al usuario @${user}?`
                );
                break;
            case "ref":
                const refs =
                    userData.recommendations &&
                    userData.recommendations.users &&
                    userData.recommendations.users.length
                        ? userData.recommendations.users
                        : [];
                this.throwPopupMessage("", <UserRefList users={refs} />);
                break;
            case "ticketAllocator":
                this.throwPopupMessage(
                    "alert",
                    `¿Cuántos tickets deseas asignar al usuario @${user}?`
                );
                break;
            case "ticketDelete":
                this.throwPopupMessage(
                    "alert",
                    `¿Cuántos tickets deseas eliminar al usuario @${user}?`
                );
                break;
            case "block":
                this.throwPopupMessage(
                    "alert",
                    `¿Deseas ${
                        userData.status === "inactive"
                            ? "desbloquear"
                            : "bloquear"
                    } al usuario @${user}?`
                );
                break;
            case "resetPassword":
                this.throwPopupMessage(
                    "alert",
                    `¿Deseas restablecer la contraseña del  usuario @${user}?`
                );
                break;
            case "logs":
                this.setState({
                    redirect: (
                        <Redirect
                            to={{
                                pathname: "/mpos/users/logs",
                                state: {
                                    username: this.state.selectedUser.username,
                                },
                            }}
                        />
                    ),
                });
                break;
            case "modifyData":
                this.throwPopupMessage("alert", `Modificar Datos `);
                const userInfo = this.state.selectedUser;
                this.setState({
                    newEmail: userInfo.email,
                    newId: userInfo.profile.identification.doc_id,
                    newIdType: userInfo.profile.identification.doc_type,
                    newName: userInfo.profile.name,
                    newPhone: userInfo.profile.phone,
                    newMobilePhone: userInfo.profile.mobile_phone,
                    newLastName: userInfo.profile.last_name,
                    newTimeout: userInfo.parking_configuration.search_time,
                    newConnectionTimeout:
                        userInfo.parking_configuration.connect_timeout,
                    serviceFilter:
                        userInfo.parking_configuration.enable_service_filter,
                });
                break;

            case "modifyVehicle":
                this.throwPopupMessage("alert", `Modificar Datos `);

                const res = await userVehicleData({
                    user_id: this.state.selectedUser._id,
                });

                const resJson = await res.json();
                const userVehicleInfo = resJson.vehicles;

                const usersVehicles = userVehicleInfo.map((vehicle) => ({
                    text: `${vehicle.vehicle.brand} ${vehicle.vehicle.model}  ${vehicle.vehicle.year} `,
                    value: vehicle.vehicle._id,
                }));

                this.setState({
                    vehicleData: userVehicleInfo,
                    usersVehicles,
                    newLicensePlate: "",
                    newBrand: "",
                    newModel: "",
                    newColor: "",
                    newType: "",
                    newYear: "",
                    newVerified: "",
                    newIsActive: "",
                    newAlias: "",
                    isVehicleSelected: false,
                });
                break;
            case "ticketsReport":
                this.throwPopupMessage(
                    "alert",
                    `Generar Reporte de tickets de este mes`
                );
                break;

            default:
                break;
        }
    };

    handleConfirmation = async (event) => {
        event.preventDefault();
        let user_id = this.state.selectedUser._id;
        let commerce_id = this.state.commerce_id;
        let status = this.state.selectedUser.status;
        const statusToSend = status === "inactive" ? "active" : "inactive";
        this.setState({
            loadingPremium: true,
        });
        let amount = this.state.amountToDiscount;
        let optionSelected = this.state.optionSelected;
        let promotionTickets = this.state.promotionTickets;
        let request = null;

        const newName = this.state.newName;
        const newLastName = this.state.newLastName;
        const newEmail = this.state.newEmail;
        const newPhone = this.state.newPhone;
        const newMobilePhone = this.state.newMobilePhone;
        const newId = this.state.newId;
        const newIdType = this.state.newIdType;
        const newTimeout = this.state.newTimeout;
        const newConnectionTimeout = this.state.newConnectionTimeout;
        const serviceFilter = this.state.serviceFilter;

        const ticketType = this.state.ticketType;

        // vehicle info

        const vehicle_id = this.state.vehicleId;
        const license_plate = this.state.newLicensePlate;
        const brand = this.state.newBrand;
        const model = this.state.newModel;
        const color = this.state.newColor;
        const type = this.state.newType;
        const year = this.state.newYear;
        const verified = this.state.newVerified;
        const is_active = this.state.newIsActive;
        const alias = this.state.newAlias;

        switch (optionSelected) {
            case "return":
                request = discountAmount(user_id, amount);
                break;
            case "Activar premium":
                request = convertToPremium(user_id, commerce_id);
                break;
            case "Desactivar premium":
                request = quitPremium(user_id, commerce_id);
                break;
            case "ticketAllocator":
                request = ticketsAllocate(
                    [user_id],
                    promotionTickets,
                    ticketType
                );
                break;
            case "ticketDelete":
                request = ticketsAllocate(
                    [user_id],
                    promotionTickets,
                    ticketType
                );
                break;
            case "resetPassword":
                request = resetUserPassword(user_id);
                break;
            case "block":
                request = usersUpdate({ user_id, status: statusToSend });
                break;
            case "modifyData":
                request = usersUpdate({
                    user_id,
                    ...(this.state.nameTouched && { name: newName }),
                    ...(this.state.lastNameTouched && {
                        last_name: newLastName,
                    }),
                    ...(this.state.idTouched && { doc_id: newId }),
                    ...(this.state.idTypeTouched && { doc_type: newIdType }),
                    ...(this.state.phoneTouched && { phone: newPhone }),
                    ...(this.state.mobilePhoneTouched && {
                        mobile_phone: newMobilePhone,
                    }),
                    ...(this.state.emailTouched && { email: newEmail }),
                    ...(this.state.timeoutTouched && {
                        search_time: newTimeout,
                    }), //  field name in DB is 'search_time'
                    ...(this.state.newConnectionTimeoutTouched && {
                        connect_timeout: newConnectionTimeout,
                    }),
                    ...(this.state.serviceFilterTouched && {
                        enable_service_filter: serviceFilter,
                    }),
                });
                break;
            case "modifyVehicle":
                request = userVehicleUpdate({
                    user_id,
                    vehicle_id,
                    license_plate,
                    brand,
                    model,
                    color,
                    type,
                    year,
                    verified,
                    is_active,
                    alias,
                });
                //const a = await request;
                break;
            case "ticketsReport":
                request = reportsAPI.userParkingTickets({
                    usuario: this.state.selectedUser.username,
                    fecha_inicio: moment(this.state.fromDateReport).format(
                        "DD-MM-YYYY"
                    ),
                    fecha_fin: moment(this.state.endDateReport).format(
                        "DD-MM-YYYY"
                    ),
                    email_usuario: this.state.operationalEmail,
                    attachment_filetype: "pdf",
                });
                break;
            case "reverseTransaction":
                request = superadminAPI.reverseOvertime({
                    transaction_id: this.state.selectedMovement._id,
                });
                break;
            case "reverseOvertimeTransaction":
                request = superadminAPI.reverseOvertime({
                    transaction_id: this.state.selectedMovement._id,
                });
                break;
            case "reversePremiumTransaction":
                request = superadminAPI.reversePremium({
                    premium_id:
                        this.state.selectedMovement.premium_details.premium_ref,
                });
                break;
            case "reverseParkingmeterTransaction":
                request = superadminAPI.reverseParkingmeterTicket({
                    pm_ticket_id:
                        this.state.selectedMovement.parkingmeter_ticket_ref._id,
                });
                break;
            case "reverseTollTransaction":
                request = superadminAPI.reverseTollTicket({
                    toll_ticket_id:
                        this.state.selectedMovement.toll_ticket_ref._id, // ver de donde viene el id
                });
                break;
            default:
                break;
        }
        try {
            const response = await request;
            const error = !response.ok && !response.created;
            const res = await response.json();
            if (res.error) {
                throw res.error;
            }
            if (error) {
                throw res.message;
            }
            this.username = this.state.selectedUser.username;
            this.setState({
                ...this.initialState,
            });
            this.getUser(this.username);
            this.throwPopupMessage(
                "success",
                res.message ? res.message : "Cambios realizados con éxito"
            );
        } catch (err) {
            this.setState({
                loadingPremium: false,
                optionSelected: "",
                selectionUserOptions: false,
            });
            this.throwPopupMessage(
                "error",
                err.description || err.message || err
            );
        }
    };

    handleDeleteVehicle = async (event) => {
        event.preventDefault();
        const user_id = this.state.selectedUser._id;
        const vehicle_id = this.state.vehicleId;

        //let commerce_id = this.state.commerce_id;
        //let status = this.state.selectedUser.status;
        //const statusToSend = status === "inactive" ? "active" : "inactive";
        this.setState({
            loadingPremium: true,
        });
        //let amount = this.state.amountToDiscount;
        let optionSelected = this.state.optionSelected;
        //let promotionTickets = this.state.promotionTickets;
        let request = null;

        switch (optionSelected) {
            case "modifyVehicle":
                request = userVehicleDelete({
                    user_id,
                    vehicle_id,
                });

                break;

            default:
                break;
        }
        try {
            const response = await request;
            const error = !response.ok && !response.created;
            const res = await response.json();
            if (res.error) {
                throw res.error;
            }
            if (error) {
                throw res.message;
            }
            this.username = this.state.selectedUser.username;
            this.setState({
                ...this.initialState,
            });
            this.getUser(this.username);
            this.throwPopupMessage(
                "success",
                res.message ? res.message : "Cambios realizados con éxito"
            );
        } catch (err) {
            this.setState({
                loadingPremium: false,
                optionSelected: "",
                selectionUserOptions: false,
            });
            this.throwPopupMessage(
                "error",
                err.description || err.message || err
            );
        }
    };

    handleAmount = (event) => {
        let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
        let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
        this.setState({
            amountInput: formattingAmount,
            amountToDiscount: priceToRequest(formattingAmount),
        });
    };

    handleNewName = (event) => {
        let newName = event.target.value.replace(/[^a-zA-ZñÑ'\s]/g, "");
        this.setState({ newName });
        if (newName === this.state.selectedUser.profile.name) {
            this.setState({ nameTouched: false });
            return;
        }
        this.setState({ nameTouched: true });
    };

    handleNewLastName = (event) => {
        let newLastName = event.target.value.replace(/[^a-zA-ZñÑ'\s]/g, "");
        this.setState({ newLastName });
        if (newLastName === this.state.selectedUser.profile.last_name) {
            this.setState({ lastNameTouched: false });
            return;
        }
        this.setState({ lastNameTouched: true });
    };

    /* Setting the state of the emailTouched to true if the newEmail is not equal to the
selectedUser.email. */
    handleNewEmail = (event) => {
        let newEmail = event.target.value;
        this.setState({ newEmail });
        if (newEmail === this.state.selectedUser.email) {
            this.setState({ emailTouched: false });
            return;
        }
        this.setState({ emailTouched: true });
    };

    handleNewPhone = (event) => {
        let newPhone = event.target.value.replace(/[^0-9\s]/g, "");
        this.setState({ newPhone });
        if (newPhone === this.state.selectedUser.profile.phone) {
            this.setState({ phoneTouched: false });
            return;
        }
        this.setState({ phoneTouched: true });
    };

    handleNewMobilePhone = (event) => {
        let newMobilePhone = event.target.value.replace(/[^0-9\s]/g, "");
        this.setState({ newMobilePhone });
        if (newMobilePhone === this.state.selectedUser.profile.mobile_phone) {
            this.setState({ mobilePhoneTouched: false });
            return;
        }
        this.setState({ mobilePhoneTouched: true });
    };

    handleNewId = (event) => {
        let newId = event.target.value.replace(/[^0-9\s]/g, "");
        this.setState({ newId });
        if (newId === this.state.selectedUser.profile.identification.doc_id) {
            this.setState({ idTouched: false });
            return;
        }
        this.setState({ idTouched: true });
    };
    handleNewIdType = (event) => {
        let newIdType = event.target.value
            .replace(/[^VEJvej\s]/g, "")
            .toUpperCase();
        this.setState({ newIdType });
        if (
            newIdType ===
            this.state.selectedUser.profile.identification.doc_type
        ) {
            this.setState({ idTypeTouched: false });
            return;
        }
        this.setState({ idTypeTouched: true });
    };
    handleNewTimeout = (event) => {
        let newTimeout = parseInt(event.target.value.replace(/[^0-9\s]/g, ""));
        this.setState({ newTimeout });
        if (
            newTimeout ===
            this.state.selectedUser.parking_configuration.search_time
        ) {
            this.setState({ timeoutTouched: false });
            return;
        }
        this.setState({ timeoutTouched: true });
    };

    handleNewConnectionTimeout = (event) => {
        let newConnectionTimeout = parseInt(
            event.target.value.replace(/[^0-9\s]/g, "")
        );
        this.setState({ newConnectionTimeout });
        if (
            newConnectionTimeout ===
            this.state.selectedUser.parking_configuration.connect_timeout
        ) {
            this.setState({ newConnectionTimeoutTouched: false });
            return;
        }
        this.setState({ newConnectionTimeoutTouched: true });
    };

    handleServiceFilterChange = (event, data) => {
        let serviceFilter = data.value;
        this.setState({ serviceFilter: data.value });
        if (
            serviceFilter ===
            this.state.selectedUser.parking_configuration.enable_service_filter
        ) {
            this.setState({ serviceFilterTouched: false });
            return;
        }
        this.setState({ serviceFilterTouched: true });
    };

    handleFromDateReport = (date) => {
        this.setState({ fromDateReport: date });
    };

    handleEndDateReport = (date) => {
        if (date) {
            // If the date exists add 23 hours
            this.setState({ endDateReport: moment(date).add(23, "hours") });
        } else {
            // If the date is null, save it like that
            this.setState({ endDateReport: date });
        }
    };
    handleSearchMovements = (event) => {
        event.preventDefault();
        let user_id = this.state.selectedUser._id;
        let fromDate = null;
        let endDate = null;
        let skip = 0;
        let limit = this.movementsPerPage + 1;
        let commerce_id = this.state.commerce_id;

        this.setState({ viewState: "movements_search_loading" });
        /*    joinSocket(this.props.token, (socket_res) => {
      console.log(socket_res.message);
    });
    addSocketListener("user funds", (socket_res) => {
      if (socket_res.error) {
        // show error popup
        this.setState({ viewState: "movements_search_done" });
        this.closePopup();

        setTimeout(() => {
          this.throwPopupMessage("error", socket_res.error.description);
        }, 250);
      }
      if (socket_res.transaction) {
        if (socket_res.transaction.customer === this.state.user) {
          let promise = getSuperAdminTransactions({
            commerce_id,
            fromDate,
            endDate,
            skip,
            limit,
            user_id,
          });
          this.handleMovementsSearchPromise(promise);
        }
      }
    });*/
        let promise = getSuperAdminTransactions({
            commerce_id,
            fromDate,
            endDate,
            skip,
            limit,
            user_id,
        });
        this.handleMovementsSearchPromise(promise);
    };
    // 6)
    handleMovementsSearchPromise = (promise) => {
        promise
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    // If it is an authentication error, clear the redux-store to close the session
                    if (
                        res.error.id === "NO_TOKEN_PROVIDED" ||
                        res.error.id === "AUTHENTICATE_FAILED" ||
                        res.error.id === "DUPLICATE_SESSION"
                    ) {
                        this.throwPopupMessage("alert", errors.AUTHENTICATION);
                        //this.props.clearReduxStore();
                        return; // prevent setState exeution on unmounted component
                    }
                    this.setState({ ...this.initialState });
                    this.throwPopupMessage("error", res.error.description);
                }
                let movements = [];
                if (res.transactions) {
                    let nextPageExist;
                    if (res.transactions.length > this.movementsPerPage) {
                        nextPageExist = true;
                        res.transactions.pop();
                    } else {
                        nextPageExist = false;
                    }
                    if (this.state.category === "gasolina") {
                        res.transactions.forEach((elem) => {
                            if (elem.transaction_tag !== "fee") {
                                movements.push(elem);
                            }
                        });
                    } else {
                        res.transactions.forEach((elem) => {
                            if (!elem.counterpart) {
                                movements.push({
                                    ...elem,
                                    counterpart: {
                                        _id: this.state.selectedUser._id,
                                        username:
                                            this.state.selectedUser.username,
                                    },
                                });
                            } else {
                                movements.push(elem);
                            }
                        });
                    }
                    this.setState({
                        viewState: "movements_search_done",
                        movements,
                        nextPageExist,
                    });
                }
            })
            .catch((err) => {
                this.setState({ ...this.initialState });
                this.throwPopupMessage(
                    "error",
                    "Ha ocurrido un error, verifique su conexión a internet"
                );
            });
    };

    handleMovementSelection = (event) => {
        const id = event.currentTarget.id;
        const movementClicked = this.state.movements.find(
            (mov) => mov._id === id
        );
        const selectedMovement =
            this.state.selectedMovement && this.state.selectedMovement._id;
        if (selectedMovement !== id) {
            this.setState({
                viewState: "movements_search_done",
                selectedMovement: movementClicked,
            });
            return;
        }
        this.setState({
            viewState: "movements_search_done",
            selectedMovement: null,
        });
    };
    // 7)
    removeSelectedUser = (event) => {
        event.preventDefault();
        this.setState({
            showResponsePanel: false,
        });
        setTimeout(() => {
            this.setState({
                ...this.initialState,
                viewState: "user_search_done",
                selectedUser: null,
            });
        }, 350);
    };

    // 13)
    setViewType = () => {
        let documentElement = document.documentElement,
            width = window.innerWidth || documentElement.clientWidth;
        if (width < 768) {
            if (this.state.viewState === "ticket_digitize_done") {
                this.setState({
                    viewType: "phone",
                    showResponsePanel: false,
                });
            } else {
                this.setState({ viewType: "phone" });
            }
        } else {
            if (this.state.viewState === "ticket_digitize_done") {
                this.setState({
                    viewType: "desktop",
                    showResponsePanel: true,
                });
            } else {
                this.setState({ viewType: "desktop" });
            }
        }
    };

    updateUserRequest = async () => {
        try {
            const response = await usersUpdate({
                user_id: this.state.selectedUser._id,
                phone: this.state.contactPhone,
            });

            const res = await response.json();
            if (res.error) throw res.error;
        } catch (error) {
            this.setState({ ...this.initialState });
            this.throwPopupMessage(
                "error",
                "Ha ocurrido un error, verifique su conexión a internet"
            );
        }
    };
    // new handlers
    handleSearchUser = (event) => {
        event.preventDefault();
        // search input
        let usersPromise = usersAutocomplete(
            this.state.searchParam,
            this.DEFAULT_RESULTS_PER_PAGE,
            this.state.currentUsersPage
        );
        // Manage the response
        this.setState({
            viewState: "user_search_loading",
        });
        this.handleUsersSearchPromise(usersPromise);
    };

    onEnter = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            this.handleSearchUser(event);
        }
    };

    handleUpdateUser = (event) => {
        event.preventDefault();
        this.updateUserRequest();
    };

    handleContactPhone = (event) => {
        let value = event.target.value.replace(/[^0-9\s]/g, "");
        value = value.replace(" ", "");
        this.setState({
            contactPhone: value,
        });
    };

    handleNextUsersPage = (event) => {
        event.preventDefault();
        // search input
        const nextPage = this.state.currentUsersPage + 1;

        this.setState((prevState) => ({
            currentUsersPage: prevState.currentUsersPage + 1,
        }));

        let usersPromise = usersAutocomplete(
            this.state.searchParam,
            this.DEFAULT_RESULTS_PER_PAGE,
            nextPage
        );
        // this.setState({
        //   currentUsersPage: nextPage,
        // });
        // Manage the response
        this.setState({
            viewState: "user_search_loading",
        });
        this.handleUsersSearchPromise(usersPromise);
    };
    handlePrevUsersPage = (event) => {
        event.preventDefault();
        // search input
        const nextPage = this.state.currentUsersPage - 1;

        this.setState((prevState) => ({
            currentUsersPage: prevState.currentUsersPage - 1,
        }));

        let usersPromise = usersAutocomplete(
            this.state.searchParam,
            this.DEFAULT_RESULTS_PER_PAGE,
            nextPage
        );
        // this.setState({
        //   currentUsersPage: nextPage,
        // });
        // Manage the response
        this.setState({
            viewState: "user_search_loading",
        });
        this.handleUsersSearchPromise(usersPromise);
    };
    handleTicketTypeChange = (event, data) => {
        this.setState({ ticketType: data.value });
    };

    onDetailOptionsChange = (event, data) => {
        event.stopPropagation();
        const optionSelected = data.value;
        this.setState({
            optionSelected,
            selectionUserOptions: true,
        });
        switch (optionSelected) {
            case "reverseTransaction":
                this.throwPopupMessage(
                    "alert",
                    `¿Seguro que desea reversar esta transacción?`
                );
                break;

            case "reversePremiumTransaction":
                this.throwPopupMessage(
                    "alert",
                    `¿Seguro que desea reversar esta transacción?`
                );
                break;
            case "reverseParkingmeterTransaction":
                this.throwPopupMessage(
                    "alert",
                    `¿Seguro que desea reversar esta transacción?`
                );
                break;
            case "reverseTollTransaction":
                this.throwPopupMessage(
                    "alert",
                    `¿Seguro que desea reversar esta transacción?`
                );
                break;
            case "reverseOvertimeTransaction":
                this.throwPopupMessage(
                    "alert",
                    `¿Seguro que desea reversar esta transacción?`
                );
                break;
            default:
                break;
        }
    };

    handleUsersVehicles = (event, { value }) => {
        const userVehicleInfo = this.state.vehicleData;
        const filteredArray = userVehicleInfo.filter(
            (item) => item.vehicle._id === value
        );

        this.setState({
            isVehicleSelected: true,
            vehicleId: filteredArray[0].vehicle._id,
            newLicensePlate: filteredArray[0].vehicle.license_plate,
            newBrand: filteredArray[0].vehicle.brand,
            newModel: filteredArray[0].vehicle.model,
            newColor: filteredArray[0].vehicle.color,
            newType: filteredArray[0].vehicle.type,
            newYear: filteredArray[0].vehicle.year,
            newVerified: filteredArray[0].vehicle.verified,
            newIsActive: filteredArray[0].vehicle.is_active,
            newAlias: filteredArray[0].alias,
        });
    };

    handleLicensePlate = (event) => {
        let newLicensePlate = event.target.value;
        this.setState({ newLicensePlate });
    };

    handleBrand = (event) => {
        let newBrand = event.target.value;
        this.setState({ newBrand });
    };

    handleModel = (event) => {
        let newModel = event.target.value;
        this.setState({ newModel });
    };

    handleColor = (event) => {
        let newColor = event.target.value;
        this.setState({ newColor });
    };

    handleType = (event, { value }) => {
        let newType = value;
        this.setState({ newType });
    };

    handleYear = (event) => {
        let newYear = event.target.value;
        this.setState({ newYear });
    };

    handleVerified = (event, { value }) => {
        let newVerified = value;
        this.setState({ newVerified });
    };

    handleAlias = (event) => {
        let newAlias = event.target.value;
        this.setState({ newAlias });
    };

    handleIsActive = (event, { value }) => {
        let newIsActive = value;
        this.setState({ newIsActive });
    };

    onStatusOrder = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const statusMap = (status) => {
            const statusMapper = {
                pending: "Pendiente",
                approved: "Aprobado",
                rejected: "Rechazado",
            };
            return statusMapper[status] || "No definido";
        };
        this.setState({ viewState: "movements_search_loading" });
        bancamigaOrderStatus(
            this.state.selectedMovement.recharge_ref.dollar_payment_ref._id
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    if (
                        res.error.id === "NO_TOKEN_PROVIDED" ||
                        res.error.id === "AUTHENTICATE_FAILED" ||
                        res.error.id === "DUPLICATE_SESSION"
                    ) {
                        this.throwPopupMessage("alert", errors.AUTHENTICATION);
                        return;
                    }
                    if (res.error.id.name === "ORDER_NOT_EXIST") {
                        this.throwPopupMessage("error", "La orden no existe");
                        return;
                    }
                }
                this.throwPopupMessage(
                    "info",
                    <div
                        style={{
                            margin: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "space-around",
                        }}
                    >
                        <h3>Estado de orden</h3>
                        <p>{`Monto: ${formatPrice(res.data.amount)} USD`}</p>
                        <p>{`Estado: ${statusMap(res.data.status)}`}</p>
                        <a
                            href={res.data.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Ver resultado
                        </a>
                    </div>
                );
            })
            .catch((err) => {
                this.throwPopupMessage(
                    "error",
                    "Ha ocurrido un error, verifique su conexión a internet"
                );
            })
            .finally(() =>
                this.setState({ viewState: "movements_search_done" })
            );
    };
    render() {
        if (this.state.viewType === "phone") {
            return (
                <MposUsersAdminPhoneView
                    // Props
                    permissions={this.props.getPermissions}
                    redirect={this.state.redirect}
                    buildings={this.props.getBuildings}
                    selectionUserOptions={this.state.selectionUserOptions}
                    optionSelected={this.state.optionSelected}
                    amountInput={this.state.amountInput}
                    handleAmount={this.handleAmount}
                    handleNewName={this.handleNewName}
                    handleNewLastName={this.handleNewLastName}
                    handleNewEmail={this.handleNewEmail}
                    handleNewPhone={this.handleNewPhone}
                    handleNewMobilePhone={this.handleNewMobilePhone}
                    handleNewId={this.handleNewId}
                    handleNewIdType={this.handleNewIdType}
                    handleNewTimeout={this.handleNewTimeout}
                    handleServiceFilterChange={this.handleServiceFilterChange}
                    handleNewConnectionTimeout={this.handleNewConnectionTimeout}
                    newName={this.state.newName}
                    newLastName={this.state.newLastName}
                    newEmail={this.state.newEmail}
                    newPhone={this.state.newPhone}
                    newMobilePhone={this.state.newMobilePhone}
                    newId={this.state.newId}
                    newIdType={this.state.newIdType}
                    newTimeout={this.state.newTimeout}
                    serviceFilter={this.state.serviceFilter}
                    newConnectionTimeout={this.state.newConnectionTimeout}
                    promotionTickets={this.state.promotionTickets}
                    viewType={this.state.viewType}
                    viewState={this.state.viewState}
                    searchParam={this.state.searchParam}
                    users={this.state.users}
                    remoteAccess={this.remoteAccess}
                    remoteStateToBack={this.remoteStateToBack}
                    showResponsePanel={this.state.showResponsePanel}
                    selectedUser={this.state.selectedUser}
                    selectedMovement={this.state.selectedMovement}
                    popupMessages={this.state.popupMessages}
                    popupMessagesType={this.state.popupMessagesType}
                    showPopup={this.state.showPopup}
                    location={this.props.location}
                    nextUsersPageExists={this.state.nextUsersPageExists}
                    currentUsersPage={this.state.currentUsersPage}
                    handleNextUsersPage={this.handleNextUsersPage}
                    handlePrevUsersPage={this.handlePrevUsersPage}
                    handleTicketTypeChange={this.handleTicketTypeChange}
                    ticketType={this.state.ticketType}
                    // Methods
                    handleSearchUser={this.handleSearchUser}
                    handleDeletePromotionTickets={
                        this.handleDeletePromotionTickets
                    }
                    loadingPremium={this.state.loadingPremium}
                    showMovementsSearch={this.state.showMovementsSearch}
                    removeMovementsView={this.removeMovementsView}
                    searchType={this.state.searchType}
                    searchFilter={this.state.searchFilter}
                    searchCurrency={this.state.searchCurrency}
                    fromDate={this.state.fromDate}
                    endDate={this.state.endDate}
                    movements={this.state.movements}
                    nextPageExist={this.state.nextPageExist}
                    currentPage={this.state.currentPage}
                    handleOptionsSelection={this.handleOptionsSelection}
                    handlePageDown={this.handlePageDown}
                    handlePageUp={this.handlePageUp}
                    handleSearchMovements={this.handleSearchMovements}
                    handleConfirmation={this.handleConfirmation}
                    handlePromotionTickets={this.handlePromotionTickets}
                    handleMovementSelection={this.handleMovementSelection}
                    handleSearchTypeButtons={this.handleSearchTypeButtons}
                    handleSearchFilterButtons={this.handleSearchFilterButtons}
                    handleSubmit={this.handleSubmit}
                    handleSearchCurrencyButtons={
                        this.handleSearchCurrencyButtons
                    }
                    handleFromDate={this.handleFromDate}
                    handleEndDate={this.handleEndDate}
                    handleSearchInputChange={this.handleSearchInputChange}
                    handleUserSelection={this.handleUserSelection}
                    removeSelectedUser={this.removeSelectedUser}
                    closePopup={this.closePopup}
                    contactPhone={this.state.contactPhone}
                    handleContactPhone={this.handleContactPhone}
                    handleUpdateUser={this.handleUpdateUser}
                    fromDateReport={this.state.fromDateReport}
                    endDateReport={this.state.endDateReport}
                    handleFromDateReport={this.handleFromDateReport}
                    handleEndDateReport={this.handleEndDateReport}
                    onDetailOptionsChange={this.onDetailOptionsChange}
                    onStatusOrder={this.onStatusOrder}
                    transactionTags={this.props.transactionTags}
                />
            );
        }

        return (
            <MposUsersAdminView
                // Props
                permissions={this.props.getPermissions}
                redirect={this.state.redirect}
                buildings={this.props.getBuildings}
                viewState={this.state.viewState}
                searchParam={this.state.searchParam}
                optionSelected={this.state.optionSelected}
                amountInput={this.state.amountInput}
                promotionTickets={this.state.promotionTickets}
                handleAmount={this.handleAmount}
                handleNewName={this.handleNewName}
                handleNewLastName={this.handleNewLastName}
                handleNewEmail={this.handleNewEmail}
                handleNewPhone={this.handleNewPhone}
                handleNewMobilePhone={this.handleNewMobilePhone}
                handleNewId={this.handleNewId}
                handleNewIdType={this.handleNewIdType}
                handleNewTimeout={this.handleNewTimeout}
                handleTicketTypeChange={this.handleTicketTypeChange}
                ticketType={this.state.ticketType}
                newName={this.state.newName}
                newLastName={this.state.newLastName}
                newEmail={this.state.newEmail}
                newPhone={this.state.newPhone}
                newMobilePhone={this.state.newMobilePhone}
                newId={this.state.newId}
                newIdType={this.state.newIdType}
                newTimeout={this.state.newTimeout}
                serviceFilter={this.state.serviceFilter}
                newConnectionTimeout={this.state.newConnectionTimeout}
                users={this.state.users}
                selectionUserOptions={this.state.selectionUserOptions}
                remoteAccess={this.remoteAccess}
                remoteStateToBack={this.remoteStateToBack}
                showResponsePanel={this.state.showResponsePanel}
                selectedUser={this.state.selectedUser}
                selectedMovement={this.state.selectedMovement}
                popupMessages={this.state.popupMessages}
                popupMessagesType={this.state.popupMessagesType}
                showPopup={this.state.showPopup}
                location={this.props.location}
                loadingPremium={this.state.loadingPremium}
                nextUsersPageExists={this.state.nextUsersPageExists}
                currentUsersPage={this.state.currentUsersPage}
                handleNextUsersPage={this.handleNextUsersPage}
                handlePrevUsersPage={this.handlePrevUsersPage}
                // Methods
                handleSearchUser={this.handleSearchUser}
                handleDeletePromotionTickets={this.handleDeletePromotionTickets}
                removeMovementsView={this.removeMovementsView}
                showMovementsSearch={this.state.showMovementsSearch}
                searchType={this.state.searchType}
                searchFilter={this.state.searchFilter}
                searchCurrency={this.state.searchCurrency}
                fromDate={this.state.fromDate}
                endDate={this.state.endDate}
                movements={this.state.movements}
                nextPageExist={this.state.nextPageExist}
                currentPage={this.state.currentPage}
                handlePromotionTickets={this.handlePromotionTickets}
                handleServiceFilterChange={this.handleServiceFilterChange}
                handleNewConnectionTimeout={this.handleNewConnectionTimeout}
                handlePageDown={this.handlePageDown}
                handlePageUp={this.handlePageUp}
                handleOptionsSelection={this.handleOptionsSelection}
                handleSearchMovements={this.handleSearchMovements}
                handleMovementSelection={this.handleMovementSelection}
                handleConfirmation={this.handleConfirmation}
                handleSearchTypeButtons={this.handleSearchTypeButtons}
                handleSearchFilterButtons={this.handleSearchFilterButtons}
                handleSubmit={this.handleSubmit}
                handleSearchCurrencyButtons={this.handleSearchCurrencyButtons}
                handleFromDate={this.handleFromDate}
                handleEndDate={this.handleEndDate}
                handleSearchInputChange={this.handleSearchInputChange}
                handleUserSelection={this.handleUserSelection}
                closePopup={this.closePopup}
                contactPhone={this.state.contactPhone}
                handleContactPhone={this.handleContactPhone}
                handleUpdateUser={this.handleUpdateUser}
                fromDateReport={this.state.fromDateReport}
                endDateReport={this.state.endDateReport}
                handleFromDateReport={this.handleFromDateReport}
                handleEndDateReport={this.handleEndDateReport}
                onDetailOptionsChange={this.onDetailOptionsChange}
                usersVehicles={this.state.usersVehicles}
                newLicensePlate={this.state.newLicensePlate}
                newBrand={this.state.newBrand}
                newModel={this.state.newModel}
                newColor={this.state.newColor}
                newType={this.state.newType}
                newYear={this.state.newYear}
                newVerified={this.state.newVerified}
                newIsActive={this.state.newIsActive}
                newAlias={this.state.newAlias}
                isVehicleSelected={this.state.isVehicleSelected}
                handleDeleteVehicle={this.handleDeleteVehicle}
                handleUsersVehicles={this.handleUsersVehicles}
                handleLicensePlate={this.handleLicensePlate}
                handleBrand={this.handleBrand}
                handleModel={this.handleModel}
                handleColor={this.handleColor}
                handleType={this.handleType}
                handleYear={this.handleYear}
                handleVerified={this.handleVerified}
                handleAlias={this.handleAlias}
                handleIsActive={this.handleIsActive}
                onStatusOrder={this.onStatusOrder}
                transactionTags={this.props.transactionTags}
            />
        );
    }
}

const mapStateToProps = (state) => {
    let getSelectedCommerce = getSelectedCommerceFromRedux(state);
    let getPermissions = getPermissionsFromRedux(state);
    let getBuildings = getBuildingsFromRedux(state);
    let getSession = getSessionFromRedux(state);
    let transactionTags = getTransactionsTags(state);
    return {
        transactionTags,
        getSelectedCommerce,
        getPermissions,
        getBuildings,
        getSession,
    };
};

const mapDispatchToProps = {
    clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosUsers);
