//React
import React, { useEffect, useState } from "react";
import { errors } from "assets/strings/texts";
//hooks
import { useViewType } from "hooks/use-view-type";
//components
import { MposWalletPhoneView } from "./components/commerce-wallet-phone-view";
import MposWalletView from "./components/commerce-wallet-view";
import { Confirmation } from "./components/confirmation";

//Redux
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { Loader } from "components/loader";
import {
  getCommerceAmounts,
  getCommerceBankAccount,
  getConstantsBankList,
  deleteCommerceBankAccount,
} from "services/api";

const MPosCommerceWallet = ({ throwPopupMessage, closePopup }) => {
  const [viewType, setViewType] = useViewType("desktop");
  const [accountSelected, setAccountSelected] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [indexSelected, setIndexSelected] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [genLoading, setGenLoading] = useState(true);
  const [deleteAccount, setDeleteAccount] = useState(false);

  const searchBankAccounts = async () => {
    try {
      setGenLoading(true);
      const response = await getCommerceBankAccount();
      const res = await response.json();
      if (res.error) throw res.error;
      setBankAccounts(res.bank_accounts ? res.bank_accounts : []);
      setGenLoading(false);
    } catch (error) {
      throwPopupMessage(
        "error",
        error.description
          ? error.description
          : "Ha ocurrido un error, intente más tarde"
      );
      setGenLoading(false);
    }
  };
  const searchAmounts = async () => {
    try {
      setGenLoading(true);
      const response = await getCommerceAmounts();
      const res = await response.json();
      if (res.error) throw res.error;
      setWallet(res.user_amounts ? res.user_amounts : []);
      setGenLoading(false);
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        //setAuthError(true);
        //this.props.clearReduxStore();
        //return;
        throwPopupMessage("alert", errors.AUTHENTICATION);
      } else {
        throwPopupMessage(
          "error",
          error.description
            ? error.description
            : "Ha ocurrido un error, intente más tarde"
        );
        setGenLoading(false);
      }
    }
  };

  const searchConstants = async () => {
    try {
      setGenLoading(true);
      const response = await getConstantsBankList();
      const res = await response.json();
      if (res.error) throw res.error;
      if (res.bank_list) {
        let bankListAsOptions = [];
        res.bank_list.forEach((bank, index) => {
          if (bank.active) {
            bankListAsOptions.push({
              key: bank.prefix,
              text: bank.short_name,
              value: bank.short_name,
            });
          }
        });
        setBankList(bankListAsOptions);
      }

      setGenLoading(false);
    } catch (error) {
      throwPopupMessage(
        "error",
        error.description
          ? error.description
          : "Ha ocurrido un error, intente más tarde"
      );
      setGenLoading(false);
    }
  };

  useEffect(() => {
    setViewType();
    searchBankAccounts();
    searchAmounts();
    searchConstants();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const deleteFunction = async () => {
      try {
        throwPopupMessage("alert", <Loader margin={"2rem 0 0 0"} />);
        const response = await deleteCommerceBankAccount(accountSelected._id);
        const res = await response.json();
        if (res.error) throw res.error;
        throwPopupMessage("success", "Cambios realizados con éxito");
        setIsSelected(false);
        update();
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          //this.props.clearReduxStore();
          //return'
          //setAuthError(true);
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (deleteAccount) {
      deleteFunction();
      setDeleteAccount(false);
    }
  }, [deleteAccount]);

  const handleAccountSelection = (event) => {
    event.preventDefault();
    let index = event.currentTarget.id;
    setAccountSelected(bankAccounts[index]);
    setIndexSelected(parseInt(index));
    setEdit(false);
    setIsSelected(true);
    setShowForm(false);
  };

  const handleBack = (event) => {
    event.preventDefault();
    setIsSelected(false);
    setShowForm(false);
  };
  const handleShowForm = (event) => {
    event.preventDefault();
    setIsSelected(false);
    setShowForm(true);
  };

  const update = () => {
    searchBankAccounts();
    searchAmounts();
    setAccountSelected(null);
    setIsSelected(false);
    setShowForm(false);
    setEdit(false);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    setDeleteAccount(true);
  };

  const deleteConfirmation = () => {
    throwPopupMessage(
      "alert",
      <Confirmation
        handleClose={closePopup}
        handleSubmit={handleDelete}
        text={"¿Desea eliminar la cuenta seleccionada?"}
      />
    );
  };

  const handleOptions = (event, data) => {
    event.preventDefault();
    const optionSelected = data.value;
    let index;
    data.options.map((option) => {
      if (option.value === optionSelected)
        index = JSON.parse(option.key)[option.value];
      return null;
    });
    setAccountSelected(bankAccounts[index]);
    setIndexSelected(index);
    switch (optionSelected) {
      case "edit":
        setEdit(true);
        setIsSelected(true);
        setShowForm(false);
        break;
      case "delete":
        deleteConfirmation();
        break;
      default:
        break;
    }
  };

  return viewType === "phone" ? (
    <MposWalletPhoneView
      viewType={viewType}
      handleBack={handleBack}
      isSelected={isSelected}
      edit={edit}
      showForm={showForm}
      handleShowForm={handleShowForm}
      handleAccountSelection={handleAccountSelection}
      accountSelected={accountSelected}
      bankAccounts={bankAccounts}
      bankList={bankList}
      genLoading={genLoading}
      wallet={wallet}
      handleOptions={handleOptions}
      update={update}
      indexSelected={indexSelected}
    />
  ) : (
    <MposWalletView
      viewType={viewType}
      handleBack={handleBack}
      isSelected={isSelected}
      edit={edit}
      showForm={showForm}
      handleShowForm={handleShowForm}
      handleAccountSelection={handleAccountSelection}
      handleOptions={handleOptions}
      accountSelected={accountSelected}
      bankAccounts={bankAccounts}
      bankList={bankList}
      genLoading={genLoading}
      wallet={wallet}
      update={update}
      indexSelected={indexSelected}
    />
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage,
  closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosCommerceWallet);
