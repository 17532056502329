import { getToken, baseURL, baseURL_V2, micropd_02, tollURL } from "./config";
//import recharge from "../views/mpos-box-office/features/recharge";
// General ============================================================
export function signin(userdata) {
  return fetch(`${baseURL}/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userdata),
  });
}

export function acceptTerms() {
  let token = getToken();
  let url = `${baseURL}/me/agree-with-terms`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
    },
  });
}

export function signup(userdata) {
  return fetch(`${baseURL}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userdata),
  });
}

export function forgotPassword(emailObject) {
  let url = `${baseURL}/forgot-password`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(emailObject),
  });
}

export function verifyResetPasswordCode(email, code) {
  let url = `${baseURL}/verify-code?email=${email}&code=${code}`;

  return fetch(url, {
    method: "GET",
  });
}

export function resetPassword(resetPasswordData) {
  let url = `${baseURL}/reset-password`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(resetPasswordData),
  });
}

export function getUserProfile() {
  let token = getToken();
  let url = `${baseURL}/me/profile`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function updateUserProfile(profileInfo) {
  let token = getToken();
  let url = `${baseURL}/me/profile`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(profileInfo),
  });
}

export function reverseTicket(ticket_info) {
  let { ticket_id, has_premium, paid_by } = ticket_info;
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/reverse/parking`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ticket_id, has_premium, paid_by }),
  });
}
export function verifyEmail(verificationCode) {
  //V1 NO DOC...
  let token = getToken();
  let url = `${baseURL}/email-verification?code=${verificationCode}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function sendEmailVerification() {
  //V1 NO DOC...
  let token = getToken();
  let url = `${baseURL}/email-verification`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
    },
  });
}

export function updateEmail(emailObject) {
  //V1 NO DOC....
  let token = getToken();
  let url = `${baseURL}/me/email`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(emailObject),
  });
}

// ====================================================================

// Superadmin =========================================================

export function patchWorkspace(workspaceData) {
  //V1 NO DOC....
  let token = getToken();
  let url = `${baseURL}/superadmin/workspace`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(workspaceData),
  });
}

// ====================================================================

// Commerce ===========================================================
// -- Owner

export function getOwnerDashboard(todayStartIso) {
  let token = getToken();
  let url = `${baseURL}/owners/dashboard?today_start=${todayStartIso}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

// -- Seller
// -- -- Buildings

// ====================================================================

// Building Admin =====================================================

export function patchBuildingRateFromAdmin(building, newRate) {
  //V1 NO DOC
  let token = getToken();
  let url = `${baseURL}/admin/buildings/rates`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ building, rate: newRate }),
  });
}

export function getTransactions(
  fromDate,
  endDate,
  skip,
  limit,
  type,
  currency,
  transaction_tag
) {
  let token = getToken();
  // Manage when the from and end date are unset
  if (!fromDate) {
    fromDate = "";
  }
  if (!endDate) {
    endDate = "";
  }
  if (!type) {
    type = "";
  }
  if (!currency) {
    currency = "BS";
  }
  if (!transaction_tag) {
    transaction_tag = "";
  }
  skip = skip ? `&skip=${skip}` : "";
  limit = limit ? `&limit=${limit}` : "";

  let url = `${baseURL}/transactions?from_date=${fromDate}&end_date=${endDate}${skip}${limit}&type=${type}&currency=${currency}&transaction_tag=${transaction_tag}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function getAdminTransactions(
  fromDate,
  endDate,
  skip,
  limit,
  type,
  currency,
  transaction_tag
) {
  let token = getToken();
  // Manage when the from and end date are unset
  if (!fromDate) {
    fromDate = "";
  }
  if (!endDate) {
    endDate = "";
  }
  if (!type) {
    type = "";
  }
  if (!currency) {
    currency = "BS";
  }
  if (!transaction_tag) {
    transaction_tag = "";
  }
  skip = skip ? `&skip=${skip}` : "";
  limit = limit ? `&limit=${limit}` : "";

  let url = `${baseURL}/admin/transactions?from_date=${fromDate}&end_date=${endDate}${skip}${limit}&type=${type}&currency=${currency}&transaction_tag=${transaction_tag}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function createPinPd(role, pin) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/add-pin`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pin }),
  });
}
export function verifyPinPd(role, pin) {
  if (!pin || !role) return "BAD REQUEST";
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/verify-pin`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pin }),
  });
}
export function findPinPd(role) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/find-pin`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function changePinPd(role, old_pin, pin) {
  if (!old_pin || !pin || !role) return "BAD REQUEST";
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/change-pin`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ old_pin, pin }),
  });
}

export function getBankList() {
  // Manage when the from and end date are unset
  let url = `${baseURL}/constants/banklist`;

  return fetch(url, {
    method: "GET",
  });
}
export function getBankAccounts(role) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/bank-account`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function addBankAccount(role, bank_data) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/bank-account`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bank_data),
  });
}
export function editBankAccount(role, _id, bank_data) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/bank-account/edit?id=${_id}`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bank_data),
  });
}
export function deleteBankAccount(role, _id) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/bank-account/delete?id=${_id}`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
    },
  });
}

export function withdrawNotification(role, withdraw) {
  let token = getToken();
  // Manage when the from and end date are unset
  let url = `${baseURL}/${role}/payments/withdraw`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(withdraw),
  });
}
// ====================================================================

//------POS-DIRECTO----- ///

//list bankAccounts

//withdraw funds

//-------------------------------- RUTAS UTILIZADAS  -------------------------- //

// SIGNIN AND SINGUP
export function commercesSignup(commerceData) {
  return fetch(`${baseURL}/commerces/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(commerceData),
  });
}
// check username, email and short_name existence
export function chekExistencesSignup(commerceData) {
  return fetch(`${baseURL}/commerces/check-existance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(commerceData),
  });
}

// To change password into signin
export function changePassword(password) {
  //V1 documentada
  let url = `${baseURL}/commerces/user/change-password`;
  let token = getToken();
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password }),
  });
}

export function commercesSignin(userdata) {
  //V1 documentada
  return fetch(`${baseURL}/commerces/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userdata),
  });
}
//---------------------------------------------------

// bank options

export function getConstantsBankList() {
  //V1 documentada
  let token = getToken();
  let url = `${baseURL}/constants/banklist`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}
//edit account
export function editCommerceBankAccount(_id, bank_data) {
  let token = getToken();
  let url = `${baseURL}/commerces/bank-account/edit?id=${_id}`;
  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bank_data),
  });
}

//delete account
export function deleteCommerceBankAccount(_id) {
  let token = getToken();
  let url = `${baseURL}/commerces/bank-account/delete?id=${_id}`;
  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
    },
  });
}

//add bankAccount to posDirecto
export function addCommerceBankAccount(bank_data) {
  /*
    {
    "bank_name",
    "bank_code",
    "account_name",
    "account_type",
    "account_number",
    "currency",
    "doc_id",
    "doc_type
    }
  */
  let token = getToken();
  let url = `${baseURL}/commerces/bank-account`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bank_data),
  });
}

export function getCommerceAmounts() {
  //V1 DOC...
  let token = getToken();
  let url = `${baseURL}/commerces/amounts`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function getCommerceBankAccount() {
  let token = getToken();
  let url = `${baseURL}/commerces/bank-account`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function withdrawCommerceFunds(withdraw_data) {
  //V1 doc....
  /*
    bank_account_id,
    amount,
    currency,
   */
  let token = getToken();
  let url = `${baseURL}/commerces/payments/withdraw`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(withdraw_data),
  });
}
export function withdrawCommerceFundsPreview(withdraw_data) {
  //V1 doc....
  /*
    bank_account_id,
    amount,
    currency,
   */

  let token = getToken();
  let url = `${baseURL}/commerces/payments/withdraw/preview`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(withdraw_data),
  });
}
//------------------------------------------------------

//  DASHBOARDS
//COMMON COMMERCE
export function getCommercesDashboard(start_day, period) {
  let token = getToken();
  let url = `${baseURL}/commerces/dashboard`;
  if (!period) period = "weekly";
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ start_day, period }),
  });
}

//COMMERCES PARKING
export function getParkingDashboard(todayStartIso) {
  let token = getToken();
  let url = `${baseURL_V2}/commerces/dashboard/parking?today_start=${todayStartIso}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}
export function getParkingDashboardFilter(fromDate, endDate) {
  let token = getToken();
  let url = `${baseURL_V2}/commerces/dashboard/parking-filter?fromDate=${fromDate}&endDate=${endDate}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

//COMMERCES WITH PARKING ASSOCITED
export function parkingAssociatedDashboard(todayStartIso) {
  let token = getToken();
  let url = `${baseURL_V2}/commerces/dashboard/parking-associated?today_start=${todayStartIso}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

//PAGODIRECTO
export function getPagoDirectoDashboard(todayStartIso) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/dashboard`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ start_day: todayStartIso }),
  });
}

export function getPagoDirectoParkingDashboard({
  building,
  period,
  start_date,
  end_date,
}) {
  const validPeriods = {
    daily: true,
    weekly: true,
    monthly: true,
    range: true,
  };
  const validPeriod = validPeriods[period];
  if (!validPeriod) period = "monthly";

  let token = getToken();
  let url = `${baseURL_V2}/superadmin/dashboard/parking`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ building, period, start_date, end_date }),
  });
}

export function getPagoDirectoUsersDashboard({ period, start_date, end_date }) {
  const validPeriods = {
    daily: true,
    weekly: true,
    monthly: true,
    range: true,
  };
  const validPeriod = validPeriods[period];
  if (!validPeriod) period = "monthly";

  let token = getToken();
  let url = `${baseURL_V2}/superadmin/dashboard/users`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ period, start_date, end_date }),
  });
}
//Notifications

export function sendPushNotification(
  users = [],
  title,
  body,
  date,
  screen,
  type
) {
  let token = getToken();
  let url = `${baseURL}/notifications/send`;

  let category = "customers";
  let notification_title = title ? title : "";
  let notification_body = body ? body : "";
  let event = screen ? screen : "home";
  let logo = type ? type : "general";
  let expiration_date = date ? date : null;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      users,
      category,
      notification_title,
      notification_body,
      event,
      logo,
      expiration_date,
    }),
  });
}

export function notificationConstants() {
  let token = getToken();
  let url = `${baseURL}/notification/constants`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

//---------------------------------------------------

//Transaction commerces
export function commerceTransactions({
  //V2 DOC...
  fromDate,
  endDate,
  status,
  type,
  reference,
  username,
  query,
  transaction_tag,
  limit,
  skip,
}) {
  let from_date = fromDate ? fromDate : "";
  let end_date = endDate ? endDate : "";
  let token = getToken();
  let url = `${baseURL_V2}/commerces/movements`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from_date,
      end_date,
      status,
      type,
      reference,
      username,
      query,
      transaction_tag,
      limit,
      skip,
    }),
  });
}
//Transaction commerces
export function superadminCommerceTransactions({
  //V2 DOC...
  fromDate,
  endDate,
  status,
  type,
  transaction_tag,
  limit,
  skip,
  customer_id,
  counterpart_id,
  commerce_counterpart_id,
}) {
  let from_date = fromDate ? fromDate : "";
  let end_date = endDate ? endDate : "";
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/movements`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from_date,
      end_date,
      status,
      type,
      transaction_tag,
      customer_id,
      counterpart_id,
      commerce_counterpart_id,
      limit,
      skip,
    }),
  });
}
//RECIBOS

export function getInvoices(
  fromDate,
  endDate,
  skip,
  limit,
  building,
  commerce_id
) {
  let token = getToken();
  // Manage when the from and end date are unset
  if (!fromDate) {
    fromDate = "";
  }
  if (!endDate) {
    endDate = "";
  }
  skip = skip ? skip : "";
  limit = limit ? limit : "";

  let url = `${baseURL_V2}/bills`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from_date: fromDate,
      end_date: endDate,
      skip,
      limit,
      building,
      commerce_id,
    }),
  });
}

export function getInvoiceById(id, commerce) {
  let token = getToken();
  let url = `${baseURL_V2}/bills`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, commerce_id: commerce }),
  });
}
export function markInvoiceAsPaid(paymentData) {
  let token = getToken();
  let url = `${baseURL_V2}/bills/paid`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(paymentData),
  });
}

// PARKING
export function ticketsHistory(
  //V2 DOC...
  fromDate,
  endDate,
  status = [],
  types = [],
  creationRule,
  skip,
  limit,
  commerce,
  building,
  new_user = false
) {
  let token = getToken();
  // Manage when the from and end date are unset
  fromDate = fromDate ? fromDate : "";
  endDate = endDate ? endDate : "";
  skip = skip ? skip : "";
  limit = limit ? limit : "";
  commerce = commerce ? commerce : "";
  building = building ? building : "";
  let url = `${baseURL_V2}/tickets/history`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from_date: fromDate,
      end_date: endDate,
      status,
      types,
      creationRule,
      skip,
      limit,
      commerce,
      building,
      new_user,
    }),
  });
}

export const getPendingOvertimeTransactions = ({
  fromDate: startDate,
  endDate,
  limit,
  skip,
}) => {
  let token = getToken();
  let url = `${baseURL}/superadmin/transactions/overtime`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ startDate, endDate, limit, skip }),
  });
};

export const changePendingOvertimeTransactions = (transaction_id) => {
  let token = getToken();
  let url = `${baseURL}/superadmin/transactions/overtime/status`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ transaction_id }),
  });
};

export function closeTicket(ticket_info) {
  //V1 DOC...
  let { username, ticket_id, building_exit, building_code, exit_at } =
    ticket_info;

  let token = getToken();
  let url = `${baseURL}/superadmin/tickets/closed`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      ticket_id,
      building_exit,
      building_code,
      exit_at,
    }),
  });
}

export function patchDiscardTicket(_id, username) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/tickets/discarded`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: _id, username }),
  });
}

export function ticketsAutocomplete(filterObject) {
  //V2 DOC..
  let {
    value,
    status = [],
    types = [],
    creationRule,
    to_pay,
    skip,
    limit,
    commerce,
    building,
  } = filterObject;

  let token = getToken();
  // Manage the 'empty form' case
  let input = value === "" ? "/" : value;
  to_pay = to_pay ? to_pay : false;
  skip = skip ? skip : "";
  limit = limit ? limit : "";
  commerce = commerce ? commerce : null;
  building = building ? building : null;

  let url = `${baseURL_V2}/tickets/autocomplete`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      input,
      status,
      types,
      creationRule,
      to_pay,
      skip,
      limit,
      commerce,
      building,
    }),
  });
}

export function usersTicketsAvailability(username) {
  //V2 DOC...
  let token = getToken();

  let url = `${baseURL_V2}/users/tickets/availability?username=${username}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function commerceTicketsAvailability(customer) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL}/commerces/tickets/availability`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ customer }),
  });
}
//--------------------------------------------------------

// ADMIN USERS CUSTOMER

export function usersAutocomplete(searchParam, show, page) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  searchParam = searchParam === "" ? "/" : searchParam;

  let url =
    `${baseURL_V2}/users/autocomplete?` +
    new URLSearchParams({ input: searchParam, show: show, page: page });

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function usersUpdate(userData) {
  //V2 DOC...
  // Manage the 'empty form' case
  /* 
    object = {
      user_id, -> required
      phone
    }
  */
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/update`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
}

export function getSuperAdminTransactions(reqData) {
  //V2 DOC....
  let {
    commerce_id,
    currency,
    endDate,
    fromDate,
    limit,
    skip,
    status,
    transaction_tag,
    type,
    user_extension = false,
    user_id,
  } = reqData;
  let token = getToken();
  // Manage when the from and end date are unset

  if (!commerce_id) {
    commerce_id = "";
  }
  if (!fromDate) {
    fromDate = "";
  }
  if (!endDate) {
    endDate = "";
  }
  if (!type) {
    type = "";
  }
  if (!currency) {
    currency = "BS";
  }
  if (!transaction_tag) {
    transaction_tag = "";
  }
  if (!user_id) {
    user_id = "";
  }
  if (!status) {
    status = "";
  }
  skip = skip ? skip : "";
  limit = limit ? limit : "";

  let url = `${baseURL_V2}/superadmin/transactions`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      commerce_id,
      from_date: fromDate,
      end_date: endDate,
      skip,
      limit,
      user_id,
      type,
      currency,
      transaction_tag,
      status,
      user_extension,
    }),
  });
}
export function ticketsAllocate(
  customers = [],
  quantity,
  ticketType = "promotion_tickets"
) {
  //V2 DOC....
  let token = getToken();
  // Manage the 'empty form' case

  let url = `${baseURL_V2}/superadmin/users/ticket-promotional`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ customers, quantity, type: ticketType }),
  });
}

export function discountAmount(user_id, amount) {
  //V2 DOC....
  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL_V2}/superadmin/transactions/discount`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, amount }),
  });
}

export function convertToPremium(user_id, commerce_id) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL_V2}/superadmin/premium`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, commerce_id }),
  });
}
export function quitPremium(user_id, commerce_id) {
  // V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL_V2}/superadmin/premium/disable`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, commerce_id }),
  });
}

export function premiumInfo(user_id) {
  let token = getToken();
  // Manage the 'empty form' case
  if (!user_id) user_id = "";
  let url = `${baseURL_V2}/superadmin/premium?user_id=${user_id}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function getUserComments(user_id) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  if (!user_id) user_id = "";
  let url = `${baseURL_V2}/superadmin/users/comments?customer=${user_id}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function postUserComment(customer_associated, comment, operator) {
  //V2 DOC....
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/comments`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ customer_associated, comment, operator }),
  });
}

export function patchUserComment(_id, customer_associated, comment) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/comments`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _id, customer_associated, comment }),
  });
}

export function deleteUserComment(_id, customer_associated) {
  //V2 DOC
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/comments/delete`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _id, customer_associated }),
  });
}

// recharges

export function getRecharges(username, type, status = [], skip, limit) {
  let token = getToken();
  // format username
  username = username ? encodeURIComponent(username) : "";

  // set skip and limit
  skip = skip ? skip : "";
  limit = limit ? limit : "";

  let url = `${baseURL_V2}/superadmin/recharges`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, skip, limit, type, status }),
  });
}
export function getTDCRecharges(page = 1, limit = 20) {
  let token = getToken();
  let url = `${micropd_02}/superadmin/dollar-payment/order/list`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ page, limit }),
  });
}

export function getMobilePaids(
  phone,
  type,
  amount,
  reference,
  status = [],
  skip,
  limit
) {
  let token = getToken();
  // set skip and limit
  skip = skip ? skip : "";
  limit = limit ? limit : "";

  let url = `${baseURL_V2}/superadmin/recharges/mobilepaid?`;

  let params = new URLSearchParams({
    phone,
    amount,
    reference,
    page: skip,
    show: limit,
    type,
    status,
  });

  return fetch(url + params, {
    method: "GET",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
  });
}

export function patchRecharge(rechargeId, amount, status, reason) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/recharges`;

  let rechargeData = {
    id: rechargeId,
    amount,
    status,
    reason,
  };

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rechargeData),
  });
}

export function patchRechargePending(rechargeId, user, payment_ref) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/recharges/pending`;

  let rechargeData = {
    id: rechargeId,
    recharge_id: rechargeId,
    username: user,
    payment_ref,
  };

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rechargeData),
  });
}

export function postRecharge(
  username,
  customer,
  payment_ref,
  payment_receiving_bank,
  amount
) {
  let token = getToken();
  let url = `${baseURL_V2}/recharges`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      customer,
      payment_ref,
      payment_receiving_bank,
      amount,
    }),
  });
}

export function getUserLogs({
  username,
  moduleName,
  dateFrom,
  dateTo,
  skip,
  limit,
}) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/logs`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      moduleName,
      dateFrom,
      dateTo,
      skip,
      limit,
    }),
  });
}
export function getUserLogsAsCommerce({
  username,
  moduleName,
  dateFrom,
  dateTo,
  skip,
  limit,
}) {
  let token = getToken();
  let url = `${baseURL_V2}/parking/logs`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      moduleName,
      dateFrom,
      dateTo,
      skip,
      limit,
    }),
  });
}

//-------------------------------------------------------------

// ADMIN COMMERCES USERS

export function getCommerceUsers() {
  //V1 DOC...
  let token = getToken();
  let url = `${baseURL}/commerces/users`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function getCommercesRole() {
  //V1 DOC
  let token = getToken();
  let url = `${baseURL}/commerces/role`;
  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

//To add user from admin users
export function addCommerceUser(body) {
  //V1 DOC...
  /*
    email,
    username,
    name,
    last_name,
    phone,
    role_id
   */
  let token = getToken();
  let url = `${baseURL}/commerces/user`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}
export function editCommerceUserRole(body) {
  //V1 DOC.
  /*
    user_id,
    role_id
   */
  let token = getToken();
  let url = `${baseURL}/commerces/user/role`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}
export function editCommerceUserStatus(body) {
  //V1 DOC...
  /*
    user_id,
    status
   */
  let token = getToken();
  let url = `${baseURL}/commerces/user/change-status`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

//For buildings settings
export function getBuildings(id = false) {
  //V1 DOCUMENTADED
  let idQueryString = id ? `?id=${id}` : "";

  let url = `${baseURL}/buildings${idQueryString}`;

  return fetch(url, {
    method: "GET",
  });
}
export function getTolls() {
  //V1 DOCUMENTADED

  let url = `${tollURL}/tolls`;

  return fetch(url, {
    method: "GET",
  });
}

export function patchBuildingRate(building, newRate) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/buildings/rates`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ building, rate: newRate }),
  });
}

export function updateBuildingRate(obj) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/buildings/rates`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

export function updateBuildingsPdFee(obj) {
  //V2 DOC...
  /*
    {
      buildings: IdArray,
      pd_fee: Number
    }
  */
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/buildings/pd-fee`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

export function patchBuildingAccess(accessData) {
  //V2 DOC....
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/buildings/accesses`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(accessData),
  });
}

export function postBuildingAccess(accessData) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/buildings/accesses`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(accessData),
  });
}
export function accessAutocomplete(searchParam) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  searchParam = searchParam === "" ? "/" : searchParam;

  let url = `${baseURL_V2}/accesses/autocomplete?input=${searchParam}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function postDeleteBuildingAccess(accessData) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/buildings/accesses/delete`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(accessData),
  });
}
// ADMIN COMMERCE

export function markWithdrawAsPaid(paymentData) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/withdraw/mark-paid`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(paymentData),
  });
}
//GENERAL

export function getConstants() {
  let url = `${baseURL}/constants`;

  return fetch(url, {
    method: "GET",
  });
}

// Commerce Admin

export function commercesSuperAutocomplete(searchParam, skip, limit) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case
  searchParam = searchParam === "" ? "/" : searchParam;

  let url = `${baseURL_V2}/superadmin/commerces/autocomplete?input=${searchParam}&skip=${skip}&limit=${limit}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export function changeBilling(commerceId, billingType) {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/billing-type`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: commerceId, billingType }),
  });
}

export function addPrepaidTickets(commerce, numOfTickets) {
  //V1 DOC...
  let token = getToken();
  let url = `${baseURL}/superadmin/commerces/prepaid-tickets`; // TODO: update request

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ commerce, num_tickets: numOfTickets }),
  });
}

export function patchCommerceBlock(commerceId, blocked) {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/blocked`;

  return fetch(url, {
    method: "PATCH",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: commerceId, blocked: blocked }),
  });
}

export function editCommerce({
  name,
  phone,
  address,
  doc_id,
  doc_type,
  photo_url,
}) {
  let token = getToken();
  let url = `${baseURL}/commerces/edit`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, phone, address, doc_id, doc_type, photo_url }),
  });
}

export function getBuildingCommerces({
  building_id,
  start_date,
  end_date,
  limit,
  skip,
}) {
  let token = getToken();
  let url = `${baseURL_V2}/parking/commerces/digitize`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ building_id, start_date, end_date, limit, skip }),
  });
}
export function getBuildingCommerceTickets({ commerce_id, date }) {
  let token = getToken();
  let url = `${baseURL_V2}/parking/commerces/tickets`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ commerce_id, date }),
  });
}

export function commerceAutocompleteByBuilding(searchParam, building_id) {
  //V2 DOC...
  let token = getToken();
  // Manage the 'empty form' case

  let url = `${baseURL_V2}/parking/commerces/autocomplete`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ input: searchParam, building_id }),
  });
}
// ------------------------------------------------------

// ----  commerces parking --- //

export function createCustomer(form) {
  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL}/commerces/signup/user`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  });
}

export function createBoxOffice(form) {
  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL_V2}/superadmin/signup/taquilla`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  });
}

export function updateSupportInfo(supportInfo) {
  /* 
    {
    "support_info": {
        "email": "contacto.pagodirecto@gmail.com",
        "office_numbers": ["04123667246", "04241325221"],
        "attention_hours": {
            "days": "",
            "hours": ""
        },
        "whatsapp_number": "04123667246"
    }
}
  */

  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL}/constants/support-info`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(supportInfo),
  });
}

export function addChildToUser(childInfo) {
  /* 
{
    "user_id": "5dcc5988e023ed00153f49e4",
    "name": "Hijo 1"
}
  */

  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL}/superadmin/users/child/add`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(childInfo),
  });
}

export function updateChildInfo(childInfo) {
  /* 
{
    "user_id": "5dcc5988e023ed00153f49e4",
    "child_id": "618db69ebc6c5c829c798b78",
    "name": "hijos1s",
    "status": "inactive"
}
  */

  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL}/superadmin/users/child/update`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(childInfo),
  });
}

export function removeChildToUser(childInfo) {
  /* 
{
    "user_id": "5dcc5988e023ed00153f49e4",
    "child_id": "618db763433e9f518422edb2"
}
  */

  let token = getToken();
  // Manage the 'empty form' case
  let url = `${baseURL}/superadmin/users/child/delete`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(childInfo),
  });
}

export function getChildrenFromUser(user_id) {
  /* 
{
    "user_id": "5dcc5988e023ed00153f49e4",
}
  */
  let token = getToken();
  let url = `${baseURL}/superadmin/users/child`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id: user_id }),
  });
}

export function getAmountHistory() {
  //V2 DOC...
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/dashboard/amount-history`;

  return fetch(url, {
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
}

export const bankTransfer = ({
  receiver_account,
  receiver_name,
  receiver_document,
  concept,
  amount,
  bank,
}) => {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/bank/transfer`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      receiver_account,
      receiver_name,
      receiver_document,
      concept,
      amount,
      bank,
    }),
  });
};
export const listOperationalUsers = (commerce_id) => {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/operational-user`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ commerce_id }),
  });
};

export const resetOperationalPassword = (operational_user_id) => {
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/reset-password`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ operational_user_id }),
  });
};

export const downloadInvoiceExcel = () => {
  /* let token = getToken();
  let url = `${baseURL_V2}/superadmin/commerces/reset-password`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ operacional_user_id }),
  }); */
  return setTimeout(() => {
    console.log("hi");
  }, 1000);
};

export function resetUserPassword(user_id) {
  /* 
{
    "user_id": "5dcc5988e023ed00153f49e4",
}
  */

  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/reset-password`;
  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id: user_id }),
  });
}

export function userVehicleUpdate(userData) {
  //V2 DOC...
  // Manage the 'empty form' case
  /* 
    object = {
      user_id, -> required
      phone
    }
  */
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/vehicles/update`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
}

export function userVehicleDelete(userData) {
  //V2 DOC...
  // Manage the 'empty form' case
  /* 
    object = {
      user_id, -> required
      phone
    }
  */
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/vehicles/delete`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
}

export function userVehicleData(userData) {
  //V2 DOC...
  // Manage the 'empty form' case
  /* 
    object = {
      user_id, -> required
      phone
    }
  */
  let token = getToken();
  let url = `${baseURL_V2}/superadmin/users/vehicles`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
}

export function bancamigaOrderStatus(order_id) {
  let token = getToken();
  let url = `${micropd_02}/superadmin/dollar-payment/order/status`;

  return fetch(url, {
    method: "POST",
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ order_id }),
  });
}
