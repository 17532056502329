import React from "react";
import {
  Panel,
  PanelSectionContent,
  PanelSectionHeader,
  PanelTitle,
} from "components/panel";
import { Button } from "components/buttons";
import { CSSTransition } from "react-transition-group";
import { formatPrice } from "services/utils";
import { Loader } from "components/loader";
import { IconBackContent } from "components/icon-back";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const WithdrawPreview = ({ data, show, loading, onWithdraw, back, onBack }) => {
  if (loading) return <Loader margin="40px 0 0 0" />;
  if (!show) return null;
  if (!data)
    return (
      <CSSTransition
        in={data && true}
        timeout={350}
        appear={data && true}
        classNames="panel"
      >
        <Panel>
          <IconBackContent show={back} handleBack={onBack} />
          <PanelSectionContent>
            No se pudo cargar la información del retiro
          </PanelSectionContent>
        </Panel>
      </CSSTransition>
    );
  return (
    <CSSTransition
      in={data && true}
      timeout={350}
      appear={data && true}
      classNames="panel"
    >
      <Panel>
        <IconBackContent show={back} handleBack={onBack} />
        <PanelTitle>Contenido del retiro</PanelTitle>
        <PanelSectionHeader>Porcentaje de retención ISLR:</PanelSectionHeader>
        <PanelSectionContent>
          {data.islr_withholding_percentage}%
        </PanelSectionContent>

        <PanelSectionHeader>Porcentaje de retención IVA:</PanelSectionHeader>
        <PanelSectionContent>
          {data.iva_withholding_percentage}%
        </PanelSectionContent>

        <PanelSectionHeader>Porcentaje IVA:</PanelSectionHeader>
        <PanelSectionContent>{data.iva_percentage}%</PanelSectionContent>

        <PanelSectionHeader>Retención ISLR:</PanelSectionHeader>
        <PanelSectionContent>
          {data.islr_withholding_amount}
        </PanelSectionContent>

        <PanelSectionHeader>Retención IVA:</PanelSectionHeader>
        <PanelSectionContent>{data.iva_withholding_amount}</PanelSectionContent>

        <PanelSectionHeader>Comisión:</PanelSectionHeader>
        <PanelSectionContent>{`${formatPrice(
          data.pd_fee
        )} VES`}</PanelSectionContent>

        <PanelSectionHeader>Subtotal:</PanelSectionHeader>
        <PanelSectionContent>{`${formatPrice(
          data.sub_total_amount
        )} VES`}</PanelSectionContent>

        <PanelSectionHeader>Total:</PanelSectionHeader>
        <PanelSectionContent>{`${formatPrice(
          data.total_amount
        )} VES`}</PanelSectionContent>

        <Button color="blue" onClick={onWithdraw}>
          Retirar
        </Button>
      </Panel>
    </CSSTransition>
  );
};

export default WithdrawPreview;
