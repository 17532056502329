import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { CSSTransition } from "react-transition-group";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import isotipoPd from "assets/img/logotipo-pd-app.png";
import defaultUser from "assets/img/default-user-grey.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import closeCircleIcon from "assets/img/close-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { transactionTagMapper } from "services/utils";
import { Button } from "components/buttons";
import { Loader } from "components/loader";

import { Menu } from "semantic-ui-react";
import { DropdownSimple } from "components/dropdown";

import { formatPrice } from "services/utils";
import {
    MovementListItem,
    MovementDetail,
    MovementDetailPhone,
    MovementPhoneListItem,
    MovementListHeader,
    NoMovementFound,
} from "../movements-list-item";
import { filterBuildingByCode } from "services/utils";

moment.locale("es-us");

const detailOptions = {
    parking_overtime: [
        {
            key: "reverseTransaction",
            value: "reverseOvertimeTransaction",
            text: "Reversar",
        },
    ],
    parking_premium: [
        {
            key: "reverseTransaction",
            value: "reversePremiumTransaction",
            text: "Reversar",
        },
    ],
    parking_premium_bundle: [
        {
            key: "reverseTransaction",
            value: "reversePremiumTransaction",
            text: "Reversar",
        },
    ],
    parkingmeters_new_ticket: [
        {
            key: "reverseTransaction",
            value: "reverseParkingmeterTransaction",
            text: "Reversar",
        },
    ],
    toll: [
        {
            key: "reverseTransaction",
            value: "reverseTollTransaction",
            text: "Reversar",
        },
    ],
};
const MovementListItemModel = ({
    movementDate,
    counterpart,
    totalAmount,
    currency = "BS",
    type,
    transactionTag,
    status,
    className,
    transactionLabel,
    style,
}) => {
    return (
        <dd className={className} style={style}>
            <div className="period-section">
                <p className="date">{movementDate}</p>
            </div>

            {type === "debit" && (
                <p className="amount-debit">
                    {"-" + formatPrice(totalAmount) + " " + currency}
                </p>
            )}
            {type === "credit" && (
                <p className="amount-credit">
                    {formatPrice(totalAmount) + " " + currency}
                </p>
            )}

            <div className="status">
                {status === "approved" && type === "credit" && (
                    <img src={okCircleIcon} alt="approved" />
                )}
                {status === "reversed" && type === "credit" && (
                    <img src={okCircleIcon} alt="approved" />
                )}
                {status === "approved" && type === "debit" && (
                    <img src={excamCircleIcon} alt="approved" />
                )}
                {status === "pending" && (
                    <img src={bellCircleIcon} alt="pending" />
                )}
                {status === "rejected" && (
                    <img src={closeCircleIcon} alt="rejected" />
                )}
                {type === "credit" && status === "approved" && (
                    <p className="approved">Crédito</p>
                )}
                {type === "credit" && status === "reversed" && (
                    <p className="approved">Reverso</p>
                )}
                {type === "credit" && status === "pending" && (
                    <p className="pending">Crédito-pendiente</p>
                )}
                {type === "credit" && status === "rejected" && (
                    <p className="rejected">Crédito-rechazado</p>
                )}
                {type === "debit" &&
                    (transactionTag === "withdraw" ? (
                        <p className="debit">Retiro</p>
                    ) : (
                        <p className="debit">Débito</p>
                    ))}
            </div>
            {transactionLabel && (
                <div className="tag">
                    <p>{transactionLabel}</p>
                </div>
            )}
            <div className="user">
                {counterpart === "pagodirecto" && (
                    <img src={isotipoPd} alt="paid" className="user" />
                )}
                {counterpart === "pagodirecto" && (
                    <p className="user">
                        <strong>Pago</strong>Directo
                    </p>
                )}

                {counterpart !== "pagodirecto" && (
                    <img src={defaultUser} alt="paid" className="user" />
                )}
                {counterpart !== "pagodirecto" && (
                    <p className="user">{"@" + counterpart}</p>
                )}
            </div>
        </dd>
    );
};

const MovementPhoneListItemModel = ({
    movementDate,
    counterpart,
    totalAmount,
    currency,
    type,
    transactionTag,

    transactionLabel,
    status,
    className,
    style,
}) => {
    if (!currency) currency = "BS";
    return (
        <dd className={className} style={style}>
            {status === "approved" && type === "credit" && (
                <img src={okCircleIcon} alt="approved" className="status" />
            )}
            {status === "reversed" && type === "credit" && (
                <img src={okCircleIcon} alt="approved" className="status" />
            )}
            {status === "approved" && type === "debit" && (
                <img src={excamCircleIcon} alt="approved" className="status" />
            )}
            {status === "pending" && (
                <img src={bellCircleIcon} alt="pending" className="status" />
            )}
            {status === "rejected" && (
                <img src={closeCircleIcon} alt="rejected" className="status" />
            )}

            <p className="title">Fecha</p>
            <p>{movementDate}</p>
            <p className="title">Monto</p>

            {type === "debit" && (
                <p>{"-" + formatPrice(totalAmount) + " " + currency}</p>
            )}
            {type === "credit" && (
                <p className="amount-credit">
                    {formatPrice(totalAmount) + " " + currency}
                </p>
            )}
            {transactionLabel && <p className="title">Etiqueta</p>}
            {transactionLabel && <p>{transactionLabel}</p>}

            {counterpart === "pagodirecto" && (
                <img src={isotipoPd} alt="paid" className="status" />
            )}
            {counterpart !== "pagodirecto" && (
                <img src={defaultUser} alt="paid" className="status" />
            )}
            <p className="title"> Usuario</p>
            {counterpart === "pagodirecto" && <p>{"pagoDirecto"}</p>}
            {counterpart !== "pagodirecto" && <p>{"@" + counterpart}</p>}
        </dd>
    );
};

const MovementDetailModel = ({
    handleMovementSelection,
    movement,
    selectedMovement,
    movementHour,
    buildings,
    tag,
    children,
    onDetailOptionsChange,
    id,
    onStatusOrder,
    viewState,
    className,
}) => {
    const hasCommerceCounterpart =
        movement &&
        movement.commerce_counterpart &&
        movement.commerce_counterpart.name;
    const hasOperator =
        movement && movement.operator && movement.operator.username;
    let commerceCounterPart = hasCommerceCounterpart
        ? movement.commerce_counterpart.name
        : "Desconocido";
    let description = movement.description
        ? movement.description
        : "Operación exitosa";
    const isTicket =
        description && description.search("Pago de ticket ") !== -1;
    let buildingName = "";
    if (isTicket) {
        let ticketNumber = description.replace("Pago de ticket ", "");
        let buildingCode = ticketNumber.substring(0, 4);
        let building = filterBuildingByCode({ buildings, code: buildingCode });
        if (Array.isArray(building) && building.length) {
            let buildingObject = { ...building[0] };
            buildingName = buildingObject.name;
        }
    }
    let operator = hasOperator ? movement.operator.username : "Desconocido";

    const disableOptions =
        (movement && movement.reversed && movement.reversed.was_reversed) ||
        (movement &&
            movement.transaction_tag !== "parking_overtime" &&
            movement.transaction_tag !== "parking_premium" &&
            movement.transaction_tag !== "parking_premium_bundle" &&
            movement.transaction_tag !== "parkingmeters_new_ticket" &&
            movement.transaction_tag !== "toll");

    const selectedMovementId = selectedMovement && selectedMovement._id;
    const isSelected = selectedMovementId === id;
    return (
        <div id={id} onClick={handleMovementSelection} className={className}>
            {children}
            <CSSTransition
                in={isSelected}
                mountOnEnter
                unmountOnExit
                timeout={350}
                className="Panel"
                key={movement._id}
            >
                <>
                    <div className="header">
                        <p className="col hundred">Detalles</p>
                        <Menu
                            style={{
                                border: "none",
                                paddingLeft: "85%",
                                marginTop: "-2rem",
                                background: "none",
                                boxShadow: "none",
                                position: "absolute",
                                zIndex: 1,
                                width: "40%",
                            }}
                        >
                            <Menu.Menu
                                position="right"
                                style={{ opacity: 1, zIndex: 1 }}
                            >
                                <DropdownSimple
                                    icon="ellipsis vertical"
                                    text=" "
                                    placeholder=" "
                                    selectOnBlur={false}
                                    options={
                                        detailOptions[
                                            movement && movement.transaction_tag
                                        ]
                                            ? detailOptions[
                                                  movement.transaction_tag
                                              ]
                                            : []
                                    }
                                    onChange={onDetailOptionsChange}
                                    wrapSelection={false}
                                    value={""}
                                    style={{ color: "black" }}
                                    disabled={disableOptions}
                                />
                            </Menu.Menu>
                        </Menu>
                    </div>
                    <div className="header">
                        <p
                            className={
                                movement.type === "credit"
                                    ? "col twenty-five"
                                    : "col fifty"
                            }
                        >
                            hora:
                        </p>

                        {movement.type === "credit" && (
                            <p className="col twenty-five">Estado:</p>
                        )}

                        {movement.type === "credit" && (
                            <p className="col twenty-five">Hecha por:</p>
                        )}
                        <p
                            className={
                                movement.type === "credit"
                                    ? "col twenty-five"
                                    : "col fifty"
                            }
                        >
                            Referencia:
                        </p>
                    </div>
                    <div className="item">
                        <p
                            className={
                                movement.type === "credit"
                                    ? "col twenty-five"
                                    : "col fifty"
                            }
                        >
                            {movementHour}
                        </p>
                        {movement.status === "approved" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Disponible</p>
                            )}
                        {movement.status === "reversed" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Reverso</p>
                            )}
                        {movement.status === "pending" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Pendiente</p>
                            )}
                        {movement.status === "rejected" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Sin efecto</p>
                            )}
                        {movement.type === "credit" && (
                            <p className="col twenty-five">
                                {commerceCounterPart} <br />
                                <span>{hasOperator ? operator : ""}</span>
                            </p>
                        )}

                        <p
                            className={
                                movement.type === "credit"
                                    ? "col twenty-five"
                                    : "col fifty"
                            }
                        >
                            {movement.reference}
                        </p>
                    </div>
                    {tag === "fuel" && (
                        <div className="header">
                            <p className="col fifty">Tipo:</p>
                            <p className="col twenty-five">Litros:</p>
                            <p className="col twenty-five">Surtidor:</p>
                        </div>
                    )}
                    {tag === "fuel" && (
                        <div className="item">
                            <p className="col fifty">
                                {movement.fuel_details.fuel_type}
                            </p>
                            <p className="col twenty-five">
                                {movement.fuel_details.liters}
                            </p>
                            <p className="col twenty-five">
                                {movement.fuel_details.pump}
                            </p>
                        </div>
                    )}
                    {tag === "recharge" && (
                        <div className="header">
                            <p className="col fifty">Tipo:</p>
                            <p className="col fifty">Referencia de pago:</p>
                        </div>
                    )}
                    {tag === "recharge" && (
                        <div className="item">
                            <p className="col fifty">
                                {transactionTagMapper(
                                    movement &&
                                        movement.recharge_ref &&
                                        movement.recharge_ref.type
                                        ? movement.recharge_ref.type
                                        : ""
                                )}
                            </p>
                            <p className="col fifty">
                                {movement &&
                                movement.recharge_ref &&
                                movement.recharge_ref.payment_ref
                                    ? movement.recharge_ref.payment_ref
                                    : ""}
                            </p>
                        </div>
                    )}
                    {tag === "recharge" &&
                        movement &&
                        movement.recharge_ref &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="header">
                                <p className="col hundred">Concepto:</p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement &&
                        movement.recharge_ref &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="item">
                                <p className="col hundred">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .description
                                    }
                                </p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement &&
                        movement.recharge_ref &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="header">
                                <p className="col twenty-five">DNI:</p>
                                <p className="col twenty-five">Monto USD:</p>
                                <p className="col twenty-five">Conversión:</p>
                                <p className="col twenty-five">Nombre:</p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement &&
                        movement.recharge_ref &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="item">
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .dni
                                    }
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .usd_amount
                                    }
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .usd_exchange_amount
                                    }{" "}
                                    VES
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .name
                                    }
                                </p>
                            </div>
                        )}
                    <div className="header">
                        <p className="col fifty">Descripción:</p>
                        <p className="col fifty">Fondos:</p>
                    </div>
                    <div className="item">
                        <p className="col fifty">{`${movement.description}. ${
                            isTicket ? buildingName : ""
                        }`}</p>
                        <div className="col fifty">
                            <p>
                                <b>antes:</b>
                                <span>
                                    {movement.previous_funds
                                        ? ` ${formatPrice(
                                              movement.previous_funds
                                          )} Bs`
                                        : " --"}
                                </span>
                            </p>
                            <p>
                                <b>después:</b>
                                <span>
                                    {movement.funds
                                        ? ` ${formatPrice(movement.funds)} Bs`
                                        : " --"}
                                </span>
                            </p>
                        </div>
                        {tag === "recharge" &&
                            movement &&
                            movement.recharge_ref &&
                            movement.recharge_ref.dollar_payment_ref && (
                                <div className="item ">
                                    {viewState ===
                                        "movements_search_loading" && (
                                        <Loader />
                                    )}
                                    <div className="col hundred">
                                        <Button
                                            color="blue"
                                            onClick={onStatusOrder}
                                            disabled={
                                                viewState ===
                                                "movements_search_loading"
                                            }
                                        >
                                            Ver estado de la orden
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </>
            </CSSTransition>
        </div>
    );
};
const MovementDetailPhoneModel = ({
    handleMovementSelection,
    movement,
    selectedMovement,
    movementHour,
    buildings,
    children,
    onDetailOptionsChange,
    id,
    tag,
    onStatusOrder,
    viewState,
    className,
}) => {
    const hasCommerceCounterpart =
        movement &&
        movement.commerce_counterpart &&
        movement.commerce_counterpart.name;
    const hasOperator =
        movement && movement.operator && movement.operator.username;
    let commerceCounterPart = hasCommerceCounterpart
        ? movement.commerce_counterpart.name
        : "Desconocido";
    let operator = hasOperator ? movement.operator.username : "Desconocido";
    let description = movement.description
        ? movement.description
        : "Operación exitosa";
    const isTicket =
        description && description.search("Pago de ticket ") !== -1;
    let buildingName = "";
    if (isTicket) {
        let ticketNumber = description.replace("Pago de ticket ", "");
        let buildingCode = ticketNumber.substring(0, 4);
        let building = filterBuildingByCode({ buildings, code: buildingCode });
        if (Array.isArray(building) && building.length) {
            let buildingObject = { ...building[0] };
            buildingName = buildingObject.name;
        }
    }

    const disableOptions =
        (movement && movement.reversed && movement.reversed.was_reversed) ||
        (movement && movement.transaction_tag !== "parking_overtime");
    const selectedMovementId = selectedMovement && selectedMovement._id;
    const isSelected = selectedMovementId === id;
    return (
        <div id={id} onClick={handleMovementSelection} className={className}>
            {children}
            <CSSTransition
                in={isSelected}
                mountOnEnter
                unmountOnExit
                timeout={350}
                className="Panel"
                key={movement._id}
            >
                <>
                    <div className="header">
                        <Menu
                            style={{
                                border: "none",
                                paddingLeft: "90%",
                                marginTop: "-2rem",
                                background: "none",
                                boxShadow: "none",
                                position: "absolute",
                                zIndex: 1,
                                width: "40%",
                            }}
                        >
                            <Menu.Menu
                                position="right"
                                style={{ opacity: 1, zIndex: 1 }}
                            >
                                <DropdownSimple
                                    icon="ellipsis horizontal"
                                    text=" "
                                    placeholder=" "
                                    selectOnBlur={false}
                                    options={
                                        detailOptions[
                                            movement && movement.transaction_tag
                                        ]
                                            ? detailOptions[
                                                  movement.transaction_tag
                                              ]
                                            : []
                                    }
                                    onChange={onDetailOptionsChange}
                                    wrapSelection={false}
                                    value={""}
                                    style={{ color: "black" }}
                                    disabled={disableOptions}
                                />
                            </Menu.Menu>
                        </Menu>
                        <p
                            className={
                                movement.type === "credit"
                                    ? "col thirty"
                                    : "col fifty"
                            }
                        >
                            hora:
                        </p>
                        <p
                            className={
                                movement.type === "credit"
                                    ? "col twenty"
                                    : "col twenty-five"
                            }
                        >
                            tipo
                        </p>
                        {movement.type === "credit" && (
                            <p className="col twenty-five">Estado</p>
                        )}
                        <p className="col twenty-five">Referencia</p>
                    </div>
                    <div className="item">
                        {movement.type === "credit" ? (
                            <p className="col thirty">{movementHour}</p>
                        ) : (
                            <p className="col fifty">{movementHour}</p>
                        )}
                        {movement.type === "credit" ? (
                            <p className="col twenty">Crédito</p>
                        ) : movement.transaction_tag === "withdraw" ? (
                            <p className="col twenty-five">Retiro</p>
                        ) : (
                            <p className="col twenty-five">Débito</p>
                        )}
                        {movement.status === "approved" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Disponible</p>
                            )}
                        {movement.status === "reversed" &&
                            movement.type === "credit" && (
                                <p className="col twenty-five"> Reverso</p>
                            )}
                        {movement.status === "pending" && (
                            <p className="col twenty-five"> Pendiente</p>
                        )}
                        {movement.status === "rejected" && (
                            <p className="col twenty-five"> Sin efecto</p>
                        )}
                        <p className="col twenty-five">{movement.reference}</p>
                    </div>
                    {movement.transaction_tag === "fuel" && (
                        <div className="header">
                            <p className="col fifty">Tipo:</p>
                            <p className="col twenty-five">Litros:</p>
                            <p className="col twenty-five">Surtidor:</p>
                        </div>
                    )}
                    {movement.transaction_tag === "fuel" && (
                        <div className="item">
                            <p className="col fifty">
                                {movement.fuel_details.fuel_type}
                            </p>
                            <p className="col twenty-five">
                                {movement.fuel_details.liters}
                            </p>
                            <p className="col twenty-five">
                                {movement.fuel_details.pump}
                            </p>
                        </div>
                    )}
                    {movement.type === "credit" && (
                        <div className="header">
                            <p className="col hundred">Hecha por:</p>
                        </div>
                    )}
                    {movement.type === "credit" && (
                        <div className="item">
                            <p className="col hundred">
                                {commerceCounterPart} <br />
                                <span>{hasOperator ? operator : ""}</span>
                            </p>
                        </div>
                    )}
                    {tag === "recharge" && (
                        <div className="header">
                            <p className="col fifty">Tipo:</p>
                            <p className="col fifty">Referencia de pago:</p>
                        </div>
                    )}
                    {tag === "recharge" && (
                        <div className="item">
                            <p className="col fifty">
                                {transactionTagMapper(
                                    movement &&
                                        movement.recharge_ref &&
                                        movement.recharge_ref.type
                                        ? movement.recharge_ref.type
                                        : ""
                                )}
                            </p>
                            <p className="col fifty">
                                {movement.recharge_ref.payment_ref}
                            </p>
                        </div>
                    )}
                    {tag === "recharge" &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="header">
                                <p className="col hundred">Concepto:</p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="item">
                                <p className="col hundred">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .description
                                    }
                                </p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="header">
                                <p className="col twenty-five">DNI:</p>
                                <p className="col twenty-five">Monto USD:</p>
                                <p className="col twenty-five">Conversión:</p>
                                <p className="col twenty-five">Nombre:</p>
                            </div>
                        )}
                    {tag === "recharge" &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="item">
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .dni
                                    }
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .usd_amount
                                    }
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .usd_exchange_amount
                                    }{" "}
                                    VES
                                </p>
                                <p className="col twenty-five">
                                    {
                                        movement.recharge_ref.dollar_payment_ref
                                            .name
                                    }
                                </p>
                            </div>
                        )}
                    <div className="header">
                        <p className="col fifty">Descripción:</p>
                        <p className="col fifty">Fondos:</p>
                    </div>
                    <div className="item">
                        <p className="col fifty">{`${movement.description}. ${
                            isTicket ? buildingName : ""
                        }`}</p>
                        <div className="col fifty">
                            <p>
                                <b>antes:</b>
                                <span>
                                    {movement.previous_funds
                                        ? ` ${formatPrice(
                                              movement.previous_funds
                                          )} Bs`
                                        : " --"}
                                </span>
                            </p>
                            <p>
                                <b>después:</b>
                                <span>
                                    {movement.funds
                                        ? ` ${formatPrice(movement.funds)} Bs`
                                        : " --"}
                                </span>
                            </p>
                        </div>
                    </div>
                    {tag === "recharge" &&
                        movement.recharge_ref.dollar_payment_ref && (
                            <div className="item ">
                                {viewState === "movements_search_loading" && (
                                    <Loader />
                                )}
                                <div className="col hundred">
                                    <Button
                                        color="blue"
                                        onClick={onStatusOrder}
                                        disabled={
                                            viewState ===
                                            "movements_search_loading"
                                        }
                                    >
                                        Ver estado de la orden
                                    </Button>
                                </div>
                            </div>
                        )}
                </>
            </CSSTransition>
        </div>
    );
};

const MovementListHeaderModel = ({ className }) => {
    return (
        <dd className={className}>
            <p className="col twenty">Fecha</p>
            <p className="col twenty">Monto</p>
            <p className="col twenty">Tipo</p>
            <p className="col twenty">Etiqueta</p>
            <p className="col twenty">Usuario</p>
        </dd>
    );
};

const NoMovementFoundModel = ({ className }) => {
    return <p className={className}>No se han encontrado transacciones</p>;
};

function formatMovementsList(
    movements,
    viewState,
    handleMovementSelection,
    selectedMovement,
    buildings,
    onDetailOptionsChange,
    onStatusOrder,
    transactionTags
) {
    let movementsList = [];
    let reducedBuildings = [];
    let formatString = "DD [de] MMMM YYYY";
    let formatHourString = "hh:mm:ss a";
    if (viewState === "movements_pagination_loading") {
        return (
            <div
                className="ui active loader"
                style={{
                    marginTop: "3rem",
                    position: "relative",
                }}
            ></div>
        );
    }

    if (Array.isArray(buildings)) {
        reducedBuildings = buildings.map((building) => {
            return { code: building.code, name: building.name };
        });
    }

    if (!Array.isArray(movements) || !movements.length) {
        return <NoMovementFound />;
    }
    movementsList.push(<MovementListHeader key="header" />);
    movements.forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);
        let movementHour = moment(movement.created_at).format(formatHourString);
        const transactionLabelObj =
            transactionTags &&
            transactionTags.length &&
            transactionTags.find(
                (tag) => tag.value === movement.transaction_tag
            );
        const transactionLabel =
            transactionLabelObj && transactionLabelObj.text
                ? transactionLabelObj.text
                : movement && movement.transaction_tag
                ? movement.transaction_tag
                : "N/A";
        movementsList.push(
            <MovementDetail
                handleMovementSelection={handleMovementSelection}
                movement={movement}
                movementHour={movementHour}
                buildings={reducedBuildings}
                tag={movement.transaction_tag}
                id={movement._id}
                onDetailOptionsChange={onDetailOptionsChange}
                selectedMovement={selectedMovement}
                key={movement._id}
                viewState={viewState}
                onStatusOrder={onStatusOrder}
            >
                <MovementListItem
                    viewState={viewState}
                    movementDate={movementDate}
                    counterpart={
                        movement.counterpart
                            ? movement.counterpart.username
                            : "Yo"
                    }
                    totalAmount={movement.pd_amount}
                    currency={movement.currency}
                    type={movement.type}
                    transactionTag={movement.transaction_tag}
                    status={movement.status}
                    id={movement._id}
                    transactionLabel={transactionLabel}
                    selectedMovement={selectedMovement}
                    style={{ bordeRadius: "0", bordeBottom: "0" }}
                />
            </MovementDetail>
        );
    });

    return movementsList;
}

function formatMovementsPhoneList(
    movements,
    viewState,
    handleMovementSelection,
    selectedMovement,
    buildings,
    onDetailOptionsChange,
    onStatusOrder,
    transactionTags
) {
    // Declaramos el array que devolverá la función
    let movementsList = [];
    let reducedBuildings = [];
    let formatString = "DD / MM / YYYY";
    let formatHourString = "hh:mm:ss a";
    if (viewState === "movements_pagination_loading") {
        return (
            <div
                className="ui active loader"
                style={{
                    marginTop: "3rem",
                    position: "relative",
                }}
            ></div>
        );
    }

    if (Array.isArray(buildings)) {
        reducedBuildings = buildings.map((building) => {
            return { code: building.code, name: building.name };
        });
    }

    movements.forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);
        let movementHour = moment(movement.created_at).format(formatHourString);
        const transactionLabelObj =
            transactionTags &&
            transactionTags.length &&
            transactionTags.find(
                (tag) => tag.value === movement.transaction_tag
            );

        const transactionLabel =
            transactionLabelObj && transactionLabelObj.text
                ? transactionLabelObj.text
                : movement && movement.transaction_tag
                ? movement.transaction_tag
                : "N/A";
        movementsList.push(
            <MovementDetailPhone
                handleMovementSelection={handleMovementSelection}
                movement={movement}
                movementHour={movementHour}
                selectedMovement={selectedMovement}
                tag={movement.transaction_tag}
                id={movement._id}
                key={movement._id}
                phone={true}
                buildings={reducedBuildings}
                onDetailOptionsChange={onDetailOptionsChange}
                onStatusOrder={onStatusOrder}
                viewState={viewState}
            >
                <MovementPhoneListItem
                    movementDate={movementDate}
                    counterpart={
                        movement.counterpart
                            ? movement.counterpart.username
                            : "Yo"
                    }
                    totalAmount={movement.pd_amount}
                    transactionTag={movement.transaction_tag}
                    currency={movement.currency}
                    type={movement.type}
                    status={movement.status}
                    transactionLabel={transactionLabel}
                    style={{ bordeRadius: "0", bordeBottom: "0" }}
                />
            </MovementDetailPhone>
        );
    });

    return movementsList;
}

export {
    MovementListItemModel,
    MovementDetailModel,
    MovementDetailPhoneModel,
    MovementPhoneListItemModel,
    MovementListHeaderModel,
    NoMovementFoundModel,
    formatMovementsList,
    formatMovementsPhoneList,
};
