import { useEffect, useState } from "react";
import { getWithdrawPreview, getWithdraw } from "../services";

const useOnMountWithdraw = (state, changeState, throwPopupMessage) => {
  const [get, setGet] = useState(true);
  const [getResult, setGetResult] = useState(false);
  useEffect(() => {
    if (getResult) {
      getWithdraw(state, changeState, throwPopupMessage);
      setGetResult(false);
    }
    if (get) {
      getWithdrawPreview(state, changeState, throwPopupMessage);
      setGet(false);
    }
  }, [get, getResult]);
  return setGetResult;
};

export default useOnMountWithdraw;
