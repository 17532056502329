import React, { useContext } from "react";
import {
  Panel,
  PanelSectionContent,
  PanelSectionHeader,
  PanelTitle,
} from "components/panel";
import { Link } from "react-router-dom";
import { Button } from "components/buttons";
import { CSSTransition } from "react-transition-group";
import { Loader } from "components/loader";
import { WalletAccountsContext } from "../../../context";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const Result = ({ data, show, loading }) => {
  const { changeState, setFetchAccounts } = useContext(WalletAccountsContext);
  if (!show) return null;
  if (loading) return <Loader />;

  const onAccept = (event) => {
    event.preventDefault();
    setFetchAccounts(true);
    changeState({ getMovements: true, detailToShow: null });
  };

  if (!data)
    return (
      <CSSTransition
        in={data && true}
        timeout={350}
        appear={data && true}
        classNames="panel"
      >
        <Panel>
          <PanelSectionContent>
            No se pudo cargar la información del retiro
          </PanelSectionContent>
        </Panel>
      </CSSTransition>
    );
  console.log({ data });
  return (
    <CSSTransition
      in={data && true}
      timeout={350}
      appear={data && true}
      classNames="panel"
    >
      <Panel>
        <PanelTitle>Resultado del retiro de fondos</PanelTitle>

        <PanelSectionHeader>Mensaje:</PanelSectionHeader>
        <PanelSectionContent>{data.message}</PanelSectionContent>

        <PanelSectionHeader>Referencia:</PanelSectionHeader>
        <PanelSectionContent>{data.transfer_reference}</PanelSectionContent>

        <PanelSectionHeader>Fecha del retiro:</PanelSectionHeader>
        <PanelSectionContent>
          {moment(data.transfer_date).format("LLL")}
        </PanelSectionContent>
        <div
          style={{
            display: "flex",
            width: "100%",
            margin: 0,
            justifyContent: "space-between",
          }}
        >
          <Button color="blue" onClick={onAccept} width="45%">
            Aceptar
          </Button>
          <Link
            to={{
              pathname: "/mpos/wallet/transfer",
              state: {
                withdraw: { intern: true, ...data.withdraw_transaction },
              },
            }}
            style={{ textDecoration: "none", width: "45%" }}
            //className="link"
          >
            <Button color="green" width="100%">
              Transferir y aprobar
            </Button>
          </Link>
        </div>
      </Panel>
    </CSSTransition>
  );
};

export default Result;
