import { baseURL_V2 } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
    reverseOvertime: `${baseURL_V2}/superadmin/reverse/overtime`,
    reversePremium: `${baseURL_V2}/superadmin/premium/reverse`,
    reverseParkingmeterTicket: `${baseURL_V2}/superadmin/reverse/parkingmeter`,
    reverseTollTicket: `${baseURL_V2}/superadmin/reverse/toll`,
};

export function reverseOvertime(body) {
    /**
     * @brief
     * @attributes
     *  body = {
     *
     */
    return post(endpoints.reverseOvertime, body);
}
export function reversePremium(body) {
    /**
     * @brief
     * @attributes
     *  body = {
     *
     */
    return post(endpoints.reversePremium, body);
}
export function reverseParkingmeterTicket(body) {
    /**
     * @brief
     * @attributes
     *  body = {
     *
     */
    return post(endpoints.reverseParkingmeterTicket, body);
}
export function reverseTollTicket(body) {
    /**
     * @brief
     * @attributes
     *  body = {
     *
     */
    return post(endpoints.reverseTollTicket, body);
}
